import React, { Component } from 'react'



export default class SD_AcceptableUsePolicy extends Component {
    render() {

        const dash = Array(20).fill().map(c => <li className="icon dnaicon-dash"></li>)
        

        return (
            <div id="terms-page">
                <div className="dark-blue">
                    <h1 className="terms__title">Semi-Dedicated Acceptable Use Policy</h1>
                </div>
                <div className="page_container">
                    <p className="terms__description--padding-small">By using the Corporate Technology services, you acknowledge that you have read our set of legal documents and agree to be bound by the Terms of Services contained herein as well as all acceptable use policies incorporated by reference.</p>
                    <h1 className="service__title--center">Corporate Technology Semi-Dedicated Servers Acceptable Use Policy</h1>
                    <p className="terms__description--padding-small">An <b>Acceptable Use Policy (AUP)</b> is a set of rules and conditions systematized by a network, system, or a website owner that define and restrict how the network, system, or website may be utilized.</p>
                    <p className="terms__description--padding-small">The <b>AUP of Corporate Technology</b> is aimed at explicitly outlining the purposes for which customers CANNOT use our SEMI-DEDICATED hosting services and position data on our network servers.</p>
                    <p className="terms__description--padding-small">This Acceptable Use Policy (AUP) is accepted as part of the <a href="https://www.corporatetech.com/semi-dedicated-server-terms">Semi-Dedicated Terms of Service</a> each customer agrees to abide by when signing up for a Semi-Dedicated server account with our hosting services. Any violation of these AUP terms will be considered as a violation of our Semi-Dedicated Terms of Service.</p>
                    <p className="terms__description--padding-small">We - Corporate Technology, shall be the final part to determine whether an act constitutes a violation of this AUP and misuse of our services. We reserve our right to respond accordingly and/or immediately terminate the provision of the services, shall we become aware, and/or have sufficient evidence, of any prohibited conduct outlined in this AUP, or any other action threatening the secure environment of our Semi-Dedicated hosting customers and/or network of servers. We shall be the sole arbiter in determining the sufficiency of the present evidences.</p>
                    <p className="terms__description--padding-small">To protect our company's reputation and responsibilities, and guarantee robust and reliable Semi-Dedicated hosting services to all our customers, as well as the privacy and security of all our users, we - Corporate Technology - hereby state the following AUP conditions to come into force for each user signing up for a Semi-Dedicated server account with our hosting services:</p>

                    <div className="container-divider">
                        <ul>
                            {dash}
                        </ul>
                    </div>
                    <div className="list-container">
                    <ol className='colored_ordered_list less_than_Ten'>
                            <li><a href="#SD_system_abuse">System Abuse</a></li>
                            <li><a href="#SD_email_policy">Email Policies</a></li>
                            <li><a href="#SD_unlawful">Inappropriate or Unlawful Content</a></li>
                            <li><a href="#SD_copyrights">Copyrights</a></li>
                            <li><a href="#SD_corrective_action">Corrective Actions</a></li>
                            <li><a href="#SD_violations">Reporting Violations</a></li>
                            <li><a href="#SD_revisions">Revisions</a></li>
                        </ol>
                        <dl className='terms_individual_details'>
                            <dt id="SD_system_abuse">1. SYSTEM ABUSE</dt>
                                <dd>Any Semi-Dedicated hosting customer in violation of our system or network security will be subject to criminal and civil liability, as well as immediate account termination. Examples include, but are not limited to the following:</dd>
                                    <ul>
                                        <li>Unauthorized access, use, probe, or scan of our system's security, authentication measures, data, or traffic. Interference with the service to any user, host or network including, but not limited to: e-mail bombing, flooding, deliberate attempts to overload the system and broadcast attacks, forging of any TCP/IP packet header or any part of the header information in any e-mail or newsgroup posting.</li>
                                        <li>Any attempt at harming a server or a customer of Corporate Technology services:</li>
                                            <p>Corporate Technology will immediately and resolutely react to any attempt at misuse of an Internet account or computer, or any attempt to gain access to an internet account or computer by a Corporate Technology Semi-Dedicated hosting customer unauthorized by the account/computer owner. Such attempts include, but are not limited to the following: -Internet scamming' (tricking other people into releasing their passwords, etc.), password robbery, security hole scanning, running proxy - proxy lists, and any kind of proxy scripts (including, but not limited to php proxy and nph proxy), introducing of any computer programs (viruses, Trojan horses, trap doors, back doors, worms, time bombs, packet bombs, cancelbots, etc.) that are intended to negatively interfere with, intercept, irreversibly damage or take hold of any system, data or personal information. It WILL NOT matter whether the attacked account or computer belongs or not to Corporate Technology services. The fraud will be subject to actions based on the very nature of the attack and those may represent a warning, an account suspension or cancellation, and/or civil or criminal legal actions.</p>
                                        <li>Background operations/programs on servers:</li>
                                            <p>‘Background process' is a program that runs in the background of the operating system. What is specific for a background process is that once started, it runs simultaneously with other foreground processes. This means that the shell executing the background operation does not wait for it to finish or terminate, as it does with foreground processes, but is free to start executing more operations along with the background one. Typical background processes are ircd, irc bot, bnc, and many others.</p>
                                            <p>Background processes are not permitted on any of our Semi-Dedicated servers, as they usually lead to CPU overload and can be used for malicious and/or illegal actions. As our primary task is to provide the highest quality web hosting service to all our customers, we cannot permit any background processes to be executed and threaten the performance of our server network.</p>
                                        <li>Excessive use of system resources:</li>
                                            <p>To ensure higher server performance and quality of the Semi-Dedicated server hosting service, all Semi-Dedicated server accounts are subject to certain resource limitations concerning, but not limited to, server CPU usage, database usage, etc. If a hosting account exceeds the allowed amount of system resources, the account owner will be immediately notified to take action to decrease the usage. If such action is not instantly taken by the owner, the account will be suspended. When an excessive CPU usage is detected the account owner may be also suggested to place an order for a CPU upgrade or a higher-resources Semi-Dedicated, VPS, or Dedicated hosting package.</p>
                                            <p>If any Semi-Dedicated server hosting account is found to be causing degradation of the server and/or our hosting network's performance, regardless of the reason, the account will be subject to suspension or immediate removal, depending on the situation. Corporate Technology will be the sole arbiter in determining whether a given account is excessively using the server resources and/or causing a server degradation.</p>
                                    </ul>
                            <dt id="SD_email_policy">2. EMAIL POLICIES</dt>
                                <dd>SPAMMING IS PROHIBITED. Users MAY NOT utilize our Semi-Dedicated server hosting services to transmit, distribute, or deliver any unsolicited bulk or commercial messages over the Internet (an action known as "spam" or "spamming").</dd>
                                <dd>Spamming is a violation of ethical norms and will be interpreted as an infringement. Corporate Technology will not tolerate the sending of unsolicited bulk or commercial messages through our Semi-Dedicated server hosting services, as well as through another service that is mentioning, in some way, our customer's domain name or implies the utilization of our server network or our e-mail services for sending the unsolicited bulk or messages.</dd>
                                <dd>SPAMVERTISING IS PROHIBITED. - ‘Spamvertizing' refers to the practice of sending unsolicited spam messages for advertising a particular website.</dd>
                                <dd>If such e-mails are relayed through 3rd party (e-mail spoofing) and/or are promoting a domain hosted on our servers, this will lead to immediate account suspension unless evidence of legitimacy for non-involvement of the domain owner in the particular illegal action is provided.</dd>
                                <dd>'E-mail spoofing' is a fraudulent e-mail activity in which the sender's (3rd party) e-mail address and other parts of the e-mail header are changed to appear that the e-mail has been sent from a different source (e-mail address owner/server).</dd>
                                <dd>'Unsolicited message' is a message that is sent against the privacy policy of a newsgroup, or is sent to a recipient without their explicit permission. We, at our sole discretion, shall determine whether any of the messages you are sending is spam or spamvertizing. For your information, spamming generally includes, but is not limited to the following</dd>
                                    <ul>
                                        <li>Sending of unsolicited messages in bulk, or sending of unsolicited e-mails, which provoke complaints from recipients;</li>
                                        <li>Sending of junk mail;</li>
                                        <li>Use of distribution lists that include people who have not given prior explicit permission to be involved in such a distribution process;</li>
                                        <li>Posting commercial ads to USENET newsgroups that do not permit it</li>
                                        <li>Posting articles containing binary encoded data to non-binary newsgroups;</li>
                                        <li>Excessive and repeated posting of off-topic messages to newsgroups;</li>
                                        <li>Excessive and repeated cross-posting;</li>
                                        <li>E-mail harassment of another Internet Customer or Customers, including but not limited to, transmitting of any threatening, libellous or obscene material, or material of any nature that may be deemed offensive;</li>
                                        <li>E-mailing of age-inappropriate communications or content to anyone under the age of 18.</li>
                                        <li>BLOCK REMOVAL: If Customer actions have caused Company mail servers or Company IP address ranges to be placed on black hole lists and other mail filtering software systems used by companies on the Internet, the Customer will incur a $100 charge to the Customer's account and $100 per hour for administrative charges incurred to remove and protect mail servers and IP ranges.Drop-Box Accounts. Using this network for the receipt of replies to unsolicited mass email (spam) sent from a third-party network is prohibited.</li>
                                        <li>Header Forgery: Forgery of email headers ('spoofing') is prohibited.</li>
                                        <li>Proxy Spamming: Spamming via third-party proxy, aggregation of proxy lists, or installation of proxy mailing software is prohibited.</li>
                                        <li>Relaying. Configuration of a mail server to accept and process third-party messages for sending without user identification and authentication is prohibited.</li>
                                            <p>MASS MAILINGS: Sending a mass unsolicited email is considered spam. An unsolicited email is defined as an email sent to a recipient who has not double-opted in to mailings from the Customer. Senders of mass mailings must maintain complete and accurate records of all opt-ins, including the email and its headers if applicable, and provide such records to the Company upon request. If positive and verifiable proof of opt-in cannot be provided, complaints from recipients of the mailing are considered proof they did not subscribe and the mailing is unsolicited.</p>
                                            <p>MAILING LISTS: Company's mass mailing rules also apply to mailing lists, listservs, or mailing services contracted for by the Customer. The policy is stated as follows: An acceptable mailing list will be focused at a targeted audience that has voluntarily signed up for e-mail information using a double opt-in process or that has made their e-mail address available to the Customer for distribution of information. The list must also allow for automatic removal by all end Customers with non-distribution in the future.</p>
                                    </ul>
                            <dt id="SD_unlawful">3. INAPPROPRIATE OR UNLAWFUL CONTENT</dt>
                                <dd>CONTENT published or transmitted via Corporate Technology's Semi-Dedicated hosting services includes website content, email content, forum/blog postings, chat content, and/or any other type of content hosted on our servers, published online or transmitted through our Semi-Dedicated server hosting services.</dd>
                                <dd>The user of our Semi-Dedicated server hosting services is NOT permitted to post online content or have links to content that:</dd>
                                    <ul>
                                        <li>is defamatory, abusive or obscene, violates a person's reputation/privacy, is reasonably deemed offensive by the web community, is anti-religious or anti-human rights, or contains any type of threatening or hate speech, encourages harassment or physical harm to any group or individual, or is otherwise malicious/fraudulent and may result in complaints/liabilities/or retaliation against Corporate Technology by offended viewers;</li>
                                        <li>promotes or encourages illegal activities (e.g. illegal drugs, illegal gambling, arms trafficking, etc.) or violates any applicable law, statute, ordinance or regulation, including but not limited to the laws and regulations governing export control, unfair competition, false advertising, consumer protection, etc.</li>
                                        <li>represents pornography - any type of pornographic/adult material, including child pornography, or advertisements of pornographic websites;</li>
                                        <li>represents an infringement on copyright, patents, trademarks, trade secrets, or third party's intellectual property or proprietary rights, including pirated computer programs, hacking/phreaking software, warez, or any type of software/content (including also ROMs, ROM Emulators, media files - MP3, AVI, .RM, etc., torrent files, and others) that is copyrighted, is NOT uploaded in compliance with its license agreement or is NOT freely available for distribution.</li>
                                    </ul>
                            <dt id="SD_copyrights">4. COPYRIGHTS</dt>
                                <dd>Copyrighted material must not be placed on customers' Semi-Dedicated accounts without the explicit permission of the copyright owner or a person specifically authorized to give such permission by the copyright owner. Upon receipt of a claim for copyright infringement, or a notice of such violation, we will immediately run full investigation and, upon confirmation, will promptly remove the infringing material from the services. Further procedures (in accordance with our stated below corrective measures) will be carried out if necessary. We will assume no liability to any customer of the Semi-Dedicated hosting services for the removal of any such material.</dd>
                            <dt id="SD_corrective_action">5. CORRECTIVE ACTIONS</dt>
                                <dd>We reserve our right to be the sole arbiter in determining the seriousness of each infringement of this AUP and to immediately take corrective actions.</dd>
                                <dd>We will disable the customer's Semi-Dedicated hosting account if we deem necessary, at our sole discretion. Upon closing of the customer's account, we will send an e-mail notification to the customer, stating the reason for the termination and quoting our terms' chapter that has been violated. The customer will be allowed to respond and take measures to remedy the situation should he/she consider him/herself abused by a third party. Upon fulfilling this obligation, we may re-activate the customer's Semi-Dedicated account, if we find, at our discretion, sufficient evidence pro customer's side.</dd>
                                <dd>Closed Semi-Dedicated accounts due to repeated violations will not be re-activated. A backup may be requested, however it will be subject to certain penalty fees imposed according to the breach of this AUP terms. The final penalty fee will be determined by the type and frequency of the violations.</dd>
                            <dt id="SD_violations">6. REPORTING VIOLATIONS</dt>
                                <dd>If you need to report a violation of this AUP agreement or believe that you or your system has been subject to attack originating from our hosting network, please contact us immediately. Our team will fully investigate the situation and provide you with professional assistance.</dd>
                            <dt id="SD_revisions">7. REVISIONS</dt>
                                <dd>We reserve our right to change this AUP at any time, without prior notice. We encourage our Semi-Dedicated hosting users to periodically review this Acceptable Use Policy and our Semi-Dedicated Terms of Service.</dd>
                        </dl>
                    </div>
                </div>
            </div>
        )
    }
}
