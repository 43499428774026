import React, { Component } from 'react'
import PageContact from '../components/PageContact'
import ManufacturingServices from '../components/ManufacturingKeyServices'
import ManufacturingCustomer from '../components/CustomerCarousel/ManufacturingCustomer'
import HeroManufacturing from '../components/HeroManufacturing'
import LazyLoad from 'react-lazyload';
import VideoSection from '../components/VideoSection'

export default class Manufacturing extends Component {
    render() {
        return (
            <div id="manufacturing-page" className='industries-page'>
                 <LazyLoad height={200} offset={100} once >
                <HeroManufacturing
                    title="MANUFACTURING SOLUTIONS"
                    heroTitle="Incorporate technology with your workflow to"
                    heroTitle2="keep operations smooth and thriving."
                />
                 </LazyLoad>
                 <LazyLoad height={200} offset={100} once >
                <ManufacturingServices />
                <VideoSection
                    title='Asher Fabrics Embraces CorpTech Solutions to Optimize Growth'
                    poster="/videos/posters/asherCaffe_poster.png"
                    src='https://acdn.dnamicro.net/corporatetech/Asher_Caffe_Testimonial_Video.mp4'
                />
                 </LazyLoad>
                <div id="manufacturing-customercard">
                    <ManufacturingCustomer/>
                </div>
                 <LazyLoad height={200} offset={100} once >
                <PageContact />
                 </LazyLoad>
            </div>
        )
    }
}
