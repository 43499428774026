import React, { Component } from 'react'



export default class GT_DomainRegistration extends Component {
    render() {

        const dash = Array(20).fill().map(c => <li className="icon dnaicon-dash"></li>)
        

        return (
            <div id="terms-page">
                <div className="dark-blue">
                    <h1 className="terms__title"> Corporate Technology DOMAIN NAME REGISTRATION</h1>
                </div>
                <div className="page_container">
                    <p className="terms__description--padding-small">By using the Corporate Technology services, you acknowledge that you have read our set of legal documents and agree to be bound by the Terms of Services contained herein as well as all acceptable use policies incorporated by reference.</p>
                    <h1 className="service__title--center"> Corporate Technology Domain Name Registration</h1>
                    <p className="terms__description--padding-small">Corporate Technology works is a US company headquartered in California, U.S.A - an ICANN accredited domain registrar. Legal detail about the company can be found in the 'Contact Details' section of this document. Corporate Technologies will be referred to in this document as Corporate Technology, for the sake of brevity.</p>
                    <div className="container-divider">
                        <ul>
                            {dash}
                        </ul>
                    </div>
                    <div className="list-container">
                        <dl className='terms_individual_details'>
                            <dt>1. Corporate Technology DOMAIN NAME REGISTRATION/TRANSFER</dt>
                                <dd>Corporate Technology provides domain registrations for both generic Top Level Domains (gTLDs) and country-code Top Level Domains (ccTLDs).</dd>
                                    <ol className='abc_ordered_list' type="A">
                                        <li>Unauthorized access, use, probe, or scan of our system’s security, authentication measures, data or traffic. Interference with the service to any user, host or network including, but not limited to: e-mail bombing, flooding, deliberate attempts to overload the system and broadcast attacks, forging of any TCP/IP packet header or any part of the header information in an e-mail or newsgroup posting.</li>
                                        <li>Domain name registrations are not operative before the registry administrator activates the domain name(s). The domain name registration(s) are only active during the registration period chosen by the registrant (you). The registration period of newly registered domain names starts from the date on which the registration is approved by the registry.</li>
                                        <li>Corporate Technology and the top registry, also known as Primary Service Provider, will not be held accountable for any inadvertences, mistakes, or otherwise deviations on the side of the registry administrator in regards to requests for registration, renewal, or any modifications of already registered domain name(s).</li>
                                        <li>You, as the registrant, agree that the domain name registration is a service, which cannot be interpreted autonomously from the services described in this Agreement or a similar Agreement from the registrar. You, as a Registrant, accede that domain name registration services do not develop a proprietary significance and you will not be entitled to any such proprietary significance claims for domain name(s) registered through our company.</li>
                                        <li>The Registered Name Holder shall "indemnify and hold harmless the Registry Operator and its directors, officers, employees, and agents from and against all claims, damages, liabilities, costs, and expenses (including reasonable legal fees and expenses) arising out of or related to the Registered Name Holder's domain name registration." At its simplest, this means that if the Registry Operator (or its employees, etc.) for the registered name issued because of the Registered Name Holder’s domain name registration, the Registered Name Holder will pay the Registry Operator for all fees and expenses in defending against the suit as well as pay for any judgments or liabilities awarded. This "indemnification" is not solely limited to court cases.</li>
                                    </ol>
                                <dd>You can view the full list of Registrant Benefits, Rights and Responsibilities, approved by ICANN, on their website:</dd>
                                <dd>http://www.icann.org/en/registrars/registrant-rights-responsibilities-en.htm</dd>
                                <dd>https://www.icann.org/resources/pages/approved-with-specs-2013-09-17-en#registrant</dd>
                                <dd>If you register a.INFO domain name, you also enter into a contract of registration with Afilias, which is represented by the following Terms & Conditions:</dd>
                                <dd>http://afilias.info/policies</dd>
                                <dd>If you register a.UK, .CO.UK, .ORG.UK or .ME.UK domain name, you also enter into a contract of registration with Nominet UK, which is represented by the following Terms & Conditions:</dd>
                                <dd>http://www.nominet.uk/uk-domain-names/registering-uk-domain/legal-details/terms-and-conditions-domain-name-registration.</dd>
                            <dt>2. EXPIRATION AND RENEWAL OF SERVICES</dt>
                                <dd>Even though Corporate Technology will provide regular reminders about the expiration of the domain name(s) registered through the company, it is your obligation to keep track of when the registration service(s) will expire. Should you fail to renew a domain name before its expiration date, the domain name will be suspended or canceled.</dd>
                                    <ol className='abc_ordered_list' type="A">
                                        <li>After a domain name's expiration date, there is a period of 30 days, in which the domain name can be renewed. Certain domain name extensions do not have such a period, so leaving a domain name to expire before attempting a renewal is at your discretion.</li>
                                        <li>After the 30-day renewal period has passed, some domain names (as per TLD Registry Policies) enter a so-called Redemption Grace Period. A domain name, which has entered a Redemption Grace Period can still be renewed. However, additional fees will be added to the domain's renewal price. 
                                            <br></br>The RGP fee depends on the given TLD and on the registrar through which it is registered as follows:
                                        </li>
                                    </ol>
                                <table className="terms_table">
                                    <tr>
                                        <th>Registrar</th>
                                        <th>TLD</th>
                                        <th>RGD fee</th>
                                    </tr>
                                    <tr>
                                        <td>Corporate Technology</td>
                                        <td>.com, .net, .org, .info, .biz</td>
                                        <td>$90.00</td>
                                    </tr>
                                    <tr>
                                        <td>LogicBoxes</td>
                                        <td>.us, .in, .cc, .tv</td>
                                        <td>$90.00</td>
                                    </tr>
                                    <tr>
                                        <td>EURid</td>
                                        <td>.eu</td>
                                        <td>$20.00</td>
                                    </tr>
                                    <tr>
                                        <td>eNom</td>
                                        <td>.com, .net, .org, .info, .biz,.co, .asia, .ca, .name, .mobi, .tw, .com.tw, .org.tw, .idv.tw, .pro, .me</td>
                                        <td>$270.00</td>
                                    </tr>
                                </table>
                                <dd>You can find the official information regarding the Redemption Grace Period on ICANN's page:<a href="http://www.icann.org/registrars/redemption-proposal-14feb02.htm"> http://www.icann.org/registrars/redemption-proposal-14feb02.htm</a></dd>
                                <dd>If a Redemption Grace Period is applicable for a specific domain name, it will last between 30-42 days.</dd>
                                    <ol>
                                        <li>Extended Redemption Grace Period - the extended redemption period may add another 7 - 30 days to the domain name's redemption period. During this period the domain name owner is allowed to retrieve the domain name from deletion by contacting the domain's registrar.
                                            <br></br>If you do not redeem your domain name before the end of the Redemption Grace Period/Extended Redemption Grace Period (or the renewal period - if RGP is not applicable), Corporate Technology reserves the right to delete the domain name, as per ICANN’s Domain Deletion & Auto-renewal Policy (EDDP).
                                        </li>
                                        <li>After the redemption period of an expired domain name is over, follows the Pending Delete Period - a registry level period of up to 5 days during which no one is allowed to renew the domain name and it cannot be newly registered because it's still not returned to the public pool of domain names.</li>
                                        <li>Released by Registry Period - after the domain name's 'Pending Delete Period' is over, it is released back into the public pool of domain names and is newly available for registration.</li>
                                    </ol>
                                <dd className='sub_detail_title'>NOTE : <span>As per EURid's domain expiration policy, all .EU domain names will be suspended one day BEFORE their official expiration date and will enter a 40-day Redemption Grace Period during which they can still be renewed. However, an additional fee will be added atop the renewal price.</span></dd>
                            <dt>3. BACKORDERING EXPIRING DOMAINS</dt>
                                <dd>Backordering a domain allows you to attempt to acquire an attractive domain that expires within the upcoming few days, the moment it becomes available for registration. The service is currently available only for .COM and .NET TLDs.</dd>
                                <dd>Placing a backorder does not guarantee that you will acquire the domain name you have requested. We cannot guarantee a successful registration and will not be held liable if we are unable to register the requested domain name, because this registration process is on a first-come-first-served basis where we might be unsuccessful in our attempt to register it for you.</dd>
                                <dd>To be eligible for the backorder domain service, you will need to deposit or have enough funds in your wallet balance. The amount placed in the wallet is non-refundable, so if we do not succeed in registering the domain for you, the funds will be returned in your overall wallet balance and you can use them for another service or attempt to backorder another domain.</dd>
                            <dt>4. COMPLETE AND ACCURATE DOMAIN WHOIS INFORMATION</dt>
                                <ol className='abc_ordered_list' type="A">
                                    <li>The Registrant agrees to provide accurate and reliable contact details and correct and update them within seven (7) days of any change during the term of the domain name registration, including the full name, postal address, e-mail address, voice telephone number, and fax number if available of the Registrant; the name of an authorized person for contact purposes in the case of a Registrant that is an organization, association, or corporation; and the following data elements:
                                        <br></br><br></br>- the names of the primary nameserver and secondary nameserver(s) for the registered domain name;
                                        <br></br>- the name, postal address, e-mail address, voice telephone number, and (where available) fax number of the technical contact for the registered domain name and
                                        <br></br>- the name, postal address, e-mail address, voice telephone number, and (where available) fax number of the administrative contact for the registered domain name;
                                        <br></br><br></br>If the information provided in the Registrant's application is deemed false or incomplete by Corporate Technology, and the Registrant fails to correct the WHOIS information within 10 calendar days after the date Corporate Technology sends a notice to the Registrant, this will be treated by Corporate Technology as an immedicable infringement of the present Agreement.
                                        <br></br><br></br>You are solely responsible for keeping track of the emails that will be sent by Corporate Technology to the email address associated with the registered domain name. The inability to respond to such inquiries and emails may result in losing ownership over the domain name(s).
                                    </li>
                                    <li>You acknowledge and agree that the contact details of your domain, as well as its registration and expiration dates and the name server associated with it, will be shared with the registry operator. As required by ICANN, this information must also be made publicly available by means of a 'port 43' WHOIS service. You hereby consent and permit all such requirements and disclosures. Further, you represent and warrant that, if you are providing information about a third party, you have notified the third party of the disclosure and the purpose for the disclosure and you have obtained the third party's consent to such disclosure.
                                        <br></br><br></br>Please, NOTE that Corporate Technology is not able to restrict any access to your WHOIS record or to control in what manner or for what purposes members of the public may use the details provided in it.
                                    </li>
                                </ol>
                            <dt>5. THIRD-PARTY USE OF DOMAIN NAME REGISTRATIONS</dt>
                                <dd>Any Registrant that intends to license the use of a domain name to a third party is nonetheless the Registrant of record and is responsible for providing its full contact information and for providing and updating accurate technical and administrative contact information adequate to facilitate timely resolution of any problems that arise in connection with the registered domain name.</dd>
                                <dd>A Registrant licensing use of a registered domain name according to this provision shall accept liability for harm caused by wrongful use of the registered domain name unless it discloses the current contact information provided by the licensee and the identity of the licensee within seven (7) days to a party providing reasonable evidence of actionable harm.</dd>
                            <dt>6. OBLIGATIONS RELATED TO WHOIS DATA PROCESSING</dt>
                                <ol className='abc_ordered_list' type="A">
                                    <li>Corporate Technology shall provide notice to each new or renewed Registrant stating:
                                        <br></br><br></br>- the purposes for which any personal data collected from the applicant are intended;
                                        <br></br>- the intended recipients or categories of recipients of the data (including the registry operator and others who will receive the data from registry operator);
                                        <br></br>- which data are obligatory and which data, if any, are voluntary; and
                                        <br></br>- how the Registrant or data subject can access and, if necessary, rectify the data held about them.
                                    </li>
                                    <li>The Registrant, in turn, shall consent to the data collecting procedure stated in subsection 6-a;</li>
                                    <li>The Registrant shall represent that a notice equivalent to that described in subsection 6-a has been provided to any third-party individuals whose personal data are supplied to Registrar by the Registrant and that the Registrant has obtained consent equivalent to that described in subsection 6-b of any such third-party individuals.</li>
                                    <li>Corporate Technology shall agree that it will not process the personal data collected from the Registrant in a way incompatible with the purposes and other limitations about which it has provided notice to the Registrant in accordance with subsection 6-a above.</li>
                                    <li>Corporate Technology shall agree that it will take reasonable precautions to protect personal data from loss, misuse, unauthorized access or disclosure, alteration, or destruction.</li>
                                </ol>
                            <dt>7. OBLIGATIONS RELATED TO DOMAIN REGISTRATION DISPUTES</dt>
                                <ol className='abc_ordered_list' type="A">
                                    <li>For the adjudication of disputes concerning or arising from the use of the registered domain name the Registrant shall submit, without prejudice to other potentially applicable jurisdictions, to the jurisdiction of the courts (1) of the Registrant's domicile and (2) where Corporate Technology is located.</li>
                                    <li>The Registrant shall agree that its registration of the registered domain name shall be subject to suspension, cancellation, or transfer pursuant to any Specification or Policy, or pursuant to any registrar or registry procedure not inconsistent with any Specification or Policy, (1) to correct mistakes by Corporate Technology or the registry operator in registering the name or (2) for the resolution of disputes concerning the registered domain name.</li>
                                </ol>
                            <dt>8. ACCESS TO YOUR ACCOUNT</dt>
                                <ol>
                                    <li>You, as the registrant of the domain name(s), have the obligation to protect your Account Login Credentials, including the password, from misuse. Anyone in possession of your account login credentials will have the full authority and access to:</li>
                                        <ol>
                                            <li>initiate a transfer of the domain name(s)</li>
                                            <li>change the WHOIS information</li>
                                            <li>change the name servers (DNS)</li>
                                        </ol>
                                    <p>Such unauthorized changes and actions may disturb or annul your rights to the domain name(s) you have registered through our company.</p>
                                </ol>
                            <dt>9. SERVICE PAYMENTS, RECURRING CONTRACTS, AND CHARGEBACKS</dt>
                                <dd>When signing up for an account with Corporate Technology, the Customer agrees to pay for one year (365 days) and abide by our Order Verification Policy. Our Sales Department will strictly scrutinize each order and carefully processes each payment to ensure that no fraudulent user can take advantage of our system.</dd>
                                <dd>Corporate Technology will charge customers with fees based on the fee structure of our system at the time of the sign-up process. Corporate Technology reserves the right to introduce price changes to the services when needed. This means that the current price list at our web store may differ from the pricing of your purchase.</dd>
                                <dd>Each customer of ours will be able to take advantage of the package he/she has purchased at the introductory price levels, and with the same set of features in the course of the whole contract period. Corporate Technology reserves the right to introduce certain changes to the packages (in the form of complementary services) to the benefit of the customers.</dd>
                                <dd>When the Customer pays for a web hosting plan with a domain via credit card or PayPal, a 12-month recurring billing subscription will be created automatically for their convenience, for which the Customer will get a notification from the payment processor.</dd>
                                <dd>All automatic payments go through exactly 7 days before the due date to avoid any downtime to the Customer's websites if there is a problem with the credit card/PayPal account.</dd>
                                <dd>We’ll send the Customer a reminder of the due payment at least 24 hours before the automatic charge takes place.</dd>
                                <dd>The Customer can cancel the subscription at any time from their Control Panel -> My Account -> Recurring, or directly through PayPal - if it’s a PayPal account subscription.</dd>
                                <dd>If a chargeback request is initiated through the Customer's bank, our payment processor will impose a penalty fee of $30 on us for handling the chargeback procedure. Thus, if the Customer decides to reactivate the given domain/hosting service, the Customer will have to cover the respective service fee and the penalty fee that resulted from your chargeback.</dd>
                            <dt>10. CUSTOMER SERVICE CONTACT DETAILS</dt>
                                <dd>Customers can get in touch with our Customer Service via any of the contact options listed below:</dd>
                                    <ol>
                                        <li>Phone us at: <a href="tel:7144412900">US Phone +1 (714) 441-2900</a></li>
                                        <li>Use our Live Chat</li>
                                        <li>Send us your details:  <a href="https://www.corporatetech.com/contact-us">Contact Form.</a></li>
                                    </ol>
                        </dl>
                    </div>
                </div>
            </div>
        )
    }
}
