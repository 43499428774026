import Validator from "validator";

export default function validateInput(data) {
  console.log(
    "%c 🍑 data: ",
    "font-size:20px;background-color: #42b983;color:#fff;",
    data
  );
  let errors = {};
  if (!Validator.isEmail(data.email.trim())) {
    errors.email = "Email is invalid";
  }
  if (Validator.isEmpty(data.email)) {
    errors.email = "Email is required";
  }
  if (Validator.isEmpty(data.name)) {
    errors.name = "Name is required";
  }
  if (Validator.isEmpty(data.business_name)) {
    errors.business = "Business name is required";
  }
  if (Validator.isEmpty(data.phone)) {
    errors.phone = "Phone number is required";
  }
  if (data.service === "Other" && Validator.isEmpty(data.details)) {
    errors.details = "Details is required";
  }
  // if (data.service === "Others") {
  //     errors.service = 'Service is required'
  // }

  return {
    errors,
    isValid: Object.keys(errors).length === 0,
  };
}
