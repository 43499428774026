import React, { Component } from 'react'



export default class VPS_AcceptableUse extends Component {
    render() {

        const dash = Array(20).fill().map(c => <li className="icon dnaicon-dash"></li>)
        

        return (
            <div id="terms-page">
                <div className="dark-blue">
                    <h1 className="terms__title">Corporate Technology Virtual Private Servers Acceptable Use Policy</h1>
                </div>
                <div className="page_container">
                    <p className="terms__description--padding-small">By using the Corporate Technology services, you acknowledge that you have read our set of legal documents and agree to be bound by the Terms of Services contained herein as well as all acceptable use policies incorporated by reference.
                    </p>
                    <h1 className="service__title--center">Corporate Virtual Private Servers Acceptable Use Policy</h1>
                    <p className="terms__description--padding-small">An <b>Acceptable Use Policy (AUP)</b> is a set of rules and conditions systematized by a network, system or a website owner that define and restrict the ways in which the network, system or website may be utilized.</p>
                    <p className="terms__description--padding-small">This <b>Acceptable Use Policy (AUP)</b> is accepted as part of the VPS Terms of Service each user agrees to abide by when signing up for a VPS account with our hosting services. Any violation of these AUP terms will be considered as violation of our VPS Terms of Service.
                        <br></br>We - Corporate Technology, shall be the final part to determine whether an act constitutes a violation of this AUP and misuse of our services. We reserve our right to respond accordingly and/or immediately terminate the provision of the services, shall we become aware, and/or have sufficient evidence, of any prohibited conduct outlined in this AUP, or any other action threatening the secure environment of our VPS hosting customers and/or network of servers. We shall be the sole arbiter in determining the sufficiency of the present evidences.
                    </p>
                    <p className="terms__description--padding-small">In order to protect our company's reputation and responsibilities, and guarantee robust and reliable VPS hosting services to all our customers, as well as the privacy and security of all our individual users, we - Corporate Technology - hereby state the following AUP conditions to come into force for each user signing up for a VPS account with our hosting services:</p>
                    <div className="container-divider">
                        <ul>
                            {dash}
                        </ul>
                    </div>

                    <div className="list-container">
                        <ol className='colored_ordered_list less_than_Ten'>
                            <li><a href="#VPS_system_abuse">System Abuse</a></li>
                            <li><a href="#VPS_spam">Spam and Spamvertizing</a></li>
                            <li><a href="#VPS_unlawful">Inappropriate or Unlawful Content</a></li>
                            <li><a href="#VPS_copyrights">Copyrights</a></li>
                            <li><a href="#VPS_corrective_actions">Corrective Actions</a></li>
                            <li><a href="#VPS_violations">Reporting Violations</a></li>
                            <li><a href="#VPS_resellers">Policy For Hosting Resellers</a></li>
                            <li><a href="#VPS_revisions">Revisions</a></li>
                        </ol>
                        <dl className='terms_individual_details'>
                            <dt id="VPS_system_abuse">1. SYSTEM ABUSE</dt>
                                <dd>Any VPS customer in violation of our system or network security will be subject to criminal and civil liability, as well as immediate account termination. Examples include, but are not limited to the following:</dd>
                                    <ul>
                                        <li>Unauthorized access, use, probe, or scan of our system's security, authentication measures, data or traffic. Interference with the service to any user, host or network including, but not limited to: e-mail bombing, flooding, deliberate attempts to overload the system and broadcast attacks, forging of any TCP/IP packet header or any part of the header information in an e-mail or newsgroup posting.</li>
                                        <li>Any attempt at doing harm to a server or a customer of Corporate Technology services:</li>
                                            <p>Corporate Technology will immediately and resolutely react to any attempt at misuse of an Internet account or computer, or any attempt to gain access to an internet account or computer by a Corporate Technology VPS customer unauthorized by the account/computer owner. Such attempts include, but are not limited to the following: Internet scamming' (tricking other people into releasing their passwords, etc.), password robbery, security hole scanning, running proxy - proxy lists and any kind of proxy scripts (including, but not limited to php proxy and nph proxy), introducing of any computer programs (viruses, Trojan horses, trap doors, back doors, worms, time bombs, packet bombs, cancel bots, etc.) that are intended to negatively interfere with, intercept, irreversibly damage or take hold of any system, data or personal information. It WILL NOT matter whether the attacked account or computer belongs or not to Corporate Technology services. The fraud will be subject to actions based on the very nature of the attack and those may represent a warning, an account suspension or cancellation, and/or civil or criminal legal actions.</p>
                                        <li>Excessive use of system resources:</li>
                                            <p>In order to ensure higher server performance and quality of the VPS service, all VPS accounts are subject to certain resource limitations concerning, but not limited to, server CPU usage, Memory usage, disk-space and traffic. If a hosting account exceeds the allowed amount of system resources, the account owner will be immediately notified to take actions in order to decrease the usage. If such action is not instantly taken by the owner, the account will be suspended. When an excessive CPU usage is detected the account owner may be also suggested to place an order for a higher-resources VPS package (if available) or a semi-dedicated or dedicated hosting solution.</p>
                                            <p>If any VPS account is found to be causing degradation of the server and/or our hosting network's performance, regardless of the reason, the account will be subject to suspension or immediate removal, depending on the situation. Corporate Technology will be the sole arbiter in determining whether a given account is excessively using the server resources and/or causing a server degradation.</p>
                                        <li>Cryptocurrency mining:</li>
                                            <p>Аll forms of cryptocurrency mining and similar operations are forbidden on our servers;</p>
                                    </ul>
                            <dt di="VPS_spam">2. SPAM AND SPAMVERTIZING</dt>
                                <dd>SPAMMING IS PROHIBITED. Users MAY NOT utilize our VPS hosting services to transmit, distribute or deliver any unsolicited bulk or commercial messages over the Internet (an action known as "spam" or "spamming"). Users MAY NOT utilize our VPS hosting services for email marketing purposes. There is a 500-outbound-email-per-hour limit on all dedicated servers.</dd>
                                <dd>Spamming is a violation of ethical norms and will be interpreted as an infringement. Corporate Technology will not tolerate the sending of unsolicited bulk or commercial messages through our services, as well as through another service that is mentioning, in some way, our customer's domain name or implies the utilization of our network or our email services for sending the unsolicited bulk or messages.</dd>
                                <dd>SPAMVERTISING IS PROHIBITED. ‘Spamvertizing' refers to the practice of sending unsolicited spam messages for the purpose of advertising a particular website.</dd>
                                <dd>If such emails are relayed through 3rd party (e-mail spoofing) and/or are promoting a domain hosted on our servers, this will lead to immediate account suspension unless an evidence of legitimacy for non-involvement of the domain owner in the particular illegal action is provided.</dd>
                                <dd>'Email spoofing' is a fraudulent email activity in which the sender's (3rd party) email address and other parts of the email header are changed so as to appear that the email has been sent from a different source (e-mail address owner/server).</dd>
                                <dd>‘Unsolicited message' is a message that is sent against the privacy policy of a newsgroup, or is sent to a recipient without their explicit permission. We, at our sole discretion, shall determine whether any of the messages you are sending is spam or spamvertizing. For your information, spamming generally includes, but is not limited to the following:</dd>
                                    <ul>
                                        <li>Sending of unsolicited messages in bulk, or sending of unsolicited emails, which provoke complaints from recipients;</li>
                                        <li>Sending of junk mail;</li>
                                        <li>Use of distribution lists that include people who have not given prior explicit permission to be involved in such a distribution process;</li>
                                        <li>Posting commercial ads to USENET newsgroups that do not permit it;</li>
                                        <li>Posting articles containing binary encoded data to non-binary newsgroups;</li>
                                        <li>Excessive and repeated posting of off-topic messages to newsgroups;</li>
                                        <li>Excessive and repeated cross-posting;</li>
                                        <li>E-mail harassment of another Internet Customer or Customers, including but not limited to, transmitting of any threatening, libellous or obscene material, or material of any nature that may be deemed offensive;</li>
                                        <li>E-mailing of age-inappropriate communications or content to anyone under the age of 18.</li>
                                    </ul>
                            <dt id="VPS_unlawful">3. INAPPROPRIATE OR UNLAWFUL CONTENT</dt>
                                <dd>Copyrighted material must not be placed on Customers' VPS accounts without the explicit permission of the copyright owner or a person specifically authorized to give such permission by the copyright owner. Upon receipt of a claim for copyright infringement, or a notice of such violation, we will immediately run full investigation and, upon confirmation, will promptly remove the infringing material from the Services. Further procedures (in accordance with our stated below corrective measures) will be carried out if necessary. We will assume no liability to any Customer of the VPS Services for the removal of any such material.</dd>
                            <dt id="VPS_copyrights">4. COPYRIGHTS</dt>
                                <dd>Copyrighted material must not be placed on Customers' VPS accounts without the explicit permission of the copyright owner or a person specifically authorized to give such permission by the copyright owner. Upon receipt of a claim for copyright infringement, or a notice of such violation, we will immediately run full investigation and, upon confirmation, will promptly remove the infringing material from the Services. Further procedures (in accordance with our stated below corrective measures) will be carried out if necessary. We will assume no liability to any Customer of the VPS Services for the removal of any such material.</dd>
                            <dt id="VPS_corrective_actions">5. CORRECTIVE ACTIONS</dt>
                                <dd>We reserve our right to be the sole arbiter in determining the seriousness of each infringement of this AUP and to immediately take corrective actions.</dd>
                                <dd>We will disable the customer's VPS account if we deem necessary, at our sole discretion. Upon closing of the customer's account we will send an email notification to the customer, stating the reason for the termination and quoting our terms' chapter that has been violated. The customer will be given an opportunity to respond and take measures to remedy the situation should he/she consider him/herself abused by a third party. Upon fulfilling this obligation, we may re-activate the customer's VPS account, if we find, at our own discretion, sufficient evidence pro customer's side.</dd>
                                <dd>Closed VPS accounts due to repeated violations will not be re-activated. A backup may be requested, however it will be subject to certain penalty fees imposed according to the breach of these AUP terms. The final penalty fee will be determined by the type and frequency of the violations.</dd>
                            <dt id="VPS_violations">6. REPORTING VIOLATIONS</dt>
                                <dd>If you need to report a violation of this AUP agreement or believe that you or your system has been subject to attack originating from our hosting network, please contact us immediately. Our team will fully investigate the situation and provide you with professional assistance.</dd>
                            <dt id="VPS_resellers">7. POLICY FOR HOSTING RESELLERS</dt>
                                <dd>If you are using your virtual private server for reseller hosting purposes, you are responsible for the conduct of your Customers and by agreeing with this AUP, agree that your Customers will adhere to the AUP. Hosting resellers should make their prospective Customers aware of the AUP and the consequences for violation.</dd>
                            <dt i="VPS_revisions">8. REVISIONS</dt>
                                <dd>We reserve our right to change this AUP at any time, without prior notice. We encourage our VPS users to periodically review this Acceptable Use Policy and our VPS Terms of Service.</dd>
                        </dl>
                    </div>
                </div>
            </div>
        )
    }
}
