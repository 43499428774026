import React, { Component } from 'react'
import MeetTheTeamCarousel from '../components/MeetTheTeamCarousel'

export default class About_us extends Component {
    render() {
        return (
            <div id="about-us">
                <div className="page_container nav_adjust">
                    <div className="dialog dialog-transparent" data-aos="fade-up">
                        <h1 className="dialog_title">ABOUT US</h1>
                        <p className="dialog_description">
                            With over 20 years of servicing California, CorporateTech is the leading IT Services company 
                            that has evolved into serving customers nationwide. Technology can be very difficult to manage 
                            in-house and not to mention very costly. To eliminate these two major challenges that businesses face, 
                            CorporateTech was founded in 1999. We understand that no business is the same, so we embrace what 
                            makes your company unique by tailoring our IT service to fit your exact needs and budget.
                            <br/><br/>Take a look around our site to see how we can help you. Our industry experts are always ready to have a conversation and answer any questions to better understand your business. Then, we'd be happy to discuss a potential partnership.
                        </p>

                    </div>
                    <div className='team_section_wrapper' data-aos="fade-up">
                        <MeetTheTeamCarousel/>
                    </div>
                </div>
            </div>
        )
    }
}