import { createStore, applyMiddleware } from "redux";
import rootReducer from "./redux/reducers";
import reduxSaga from "redux-saga";
import rootSaga from "./redux/sagas";
import { composeWithDevTools } from "redux-devtools-extension";

export const getStore = () => {
  const reduxSagaMiddleware = reduxSaga();
  const store = createStore(
    rootReducer,
    composeWithDevTools(applyMiddleware(reduxSagaMiddleware))
  );
  reduxSagaMiddleware.run(rootSaga);
  return store;
};
