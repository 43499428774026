import React from 'react'


function MeetTheTeam(props) {
    return (
        <div className='team_card_container'>
            <img className='team_image' src={props.image} alt="Team profiles" />
            <div className='team_info'>
                <i className='dnaicon dnaicon-arrow-down about_icon'/>
                <p className="team_name">{props.name}</p>
                <p className="team_position">{props.position}</p>
            </div>
        </div>
    )
}

export default MeetTheTeam