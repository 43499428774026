import React from 'react'



function Card(props) {
  const { onClick = () => {} } = props
  return (
    <div className="card-wrapper" onClick={onClick}>
      <img src="/imgs/card.png" alt="card" className="card-img" />
      <div className='homecard-container'>
        <div className="homecard-wrapper">
        <img src={props.image} alt="male-customer" />
        </div>
        <div className='homecard-title'>{props.title}</div>
        <p className='homecard-description'>{props.description}</p>
      </div>
      
    </div>
  )
}

export default Card
