
import ContactUs from './ContactUs'

import { combineReducers } from 'redux';

const rootReducer = combineReducers({
  ContactUs: ContactUs

});

export default rootReducer;