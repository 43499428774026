import React from 'react'
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import CustomerCard from './CustomerCard'
import { customercard } from '../common/data'

const options = {
    responsiveClass: true,
    nav: true,
    dots: false,
    loop: true,
    autoplay: true,
    margin: 20,
    responsive: {
        0: {
            items: 1,
        },
        400: {
            items: 1,
        },
        437: {
            items: 1,
        },
        600: {
            items: 2,
        },
        700: {
            items: 2,
        },
        1000: {
            items: 2,

        },
        1024: {
            items: 3,
        },
        1366: {
            items: 4
        }
    },
};

function CustomerCarousel() {
    return(
        <div id="section-customercard">
            <div className="section-customercard--wrapper">
            <h1 className="customertitle">TRUSTED BY</h1>
            <OwlCarousel className="owl-theme team_carousel_wrapper"
                {...options}>
                {
                    customercard.map(c => (
                        
                        <CustomerCard 
                        image={c.image}
                        key={c.image}
                        />
                        
                    ))
                }
            </OwlCarousel>
            </div>
        </div>
    )
}

export default CustomerCarousel