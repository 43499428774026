import React, { Component } from 'react'
import HeroAirport from '../components/HeroAirport'
import PageContact from '../components/PageContact'
import AirportServices from '../components/AirportServices'
import AirportCustomer  from '../components/CustomerCarousel/AirportCustomer'
import LazyLoad from 'react-lazyload';
import VideoSection from '../components/VideoSection'

export default class Airport extends Component {
    render() {
        return (
            <div id="airport-page" className='industries-page'>
                <LazyLoad height={200} offset={100} once >
                <HeroAirport
                title="AIRPORT"
                heroTitle="Incorporate technology with your workflow to"
                heroTitle2="improve passenger experience and reduce the queues."
                />
                </LazyLoad>
                <LazyLoad height={200} offset={100} once >
                    <AirportServices />
                    <VideoSection
                        title='CorpTech Secures GoRentals Across 18 Locations'
                        poster='/videos/posters/goRentals_poster.png'
                        src='https://acdn.dnamicro.net/corporatetech/Go_Rentals_Testimonial.mp4'
                    />
                </LazyLoad>
                <div id="airport-customercard">
                    <AirportCustomer />
                </div>
                <LazyLoad height={200} offset={100} once >
                <PageContact />
                </LazyLoad>
            </div>
        )
    }
}
