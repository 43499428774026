import React from 'react'


function CustomerCard(props) {
    return (
        <div className='customercard'>
            <img src={props.image} alt="customercard" />
        </div>
    )
}

export default CustomerCard