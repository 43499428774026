/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {Component} from 'react';
import {pages, mainMenu } from './data.js'

const footerIndustriesPages = pages.map(a => <li><a href={a.path}>{a.name}</a></li>)
const footerIndustries =footerIndustriesPages.slice(1,10);

const footerServicesPages =pages.map(a => <li><a href={a.path}>{a.name}</a></li>)
const footerServices = footerServicesPages.slice(12,15);

const social_media = [
    {
      name: 'linkedin',
      link: 'https://www.linkedin.com/company/corporatetechinc'
    },
    {
      name: 'instagram',
      link: 'https://www.instagram.com/corporate.tech/'
    },
    {
      name: 'youtube',
      link: 'https://www.youtube.com/channel/UCdQt3MDwjNvfvUl4IAGfglA'
    }
]

class Footer extends Component {
  constructor(props) {
    super(props)
    this.state = {
      sticky: false,
      activeTab: ''
    }
  }

  render() {
    return <div className="footer">
    <div className="footer_container">
      <div className="row footer-content">
        <div id='footer__about_us' className='col-2'>
          <ul className="footer_list"> 
            <div className="footer_list--title">ABOUT US</div>
              <div className="footer_list_items">
                {
                mainMenu.map(a => <li><a  href={a.path}>{a.name}</a></li>)
                }
              </div>
          </ul>
        </div>
        <div id='footer__industries' className='col-3'>
          <ul className="footer_list"> <div className="footer_list--title">INDUSTRIES</div>
            <div className="footer_list_items">
            {footerIndustries}
            </div>
          </ul>
        </div>
        <div id='footer__service' className='col-3'>
          <ul className="footer_list"><div className="footer_list--title">SERVICE</div> 
            <div className="footer_list_items">
              {footerServices}
            </div>
          </ul>
        </div>
        <div id='footer__support' className='col-2 minus5rem-left'>
          <ul className="footer_list"> <div className="footer_list--title">SUPPORT</div> 
            <div className="footer_list_items">
              <li className="number"><a  href="tel:+1(714)441-2900"><p><span>Support: </span>+1 (714) 441-2900</p></a></li>
              <li className="number"><a  href="tel:+1(949)426-0465"><p><span>Sales: </span>+1 (949) 426-0465</p></a></li>
              <li><a className="address"><span>Irvine Office:</span> 1 Wrigley, Irvine, California 92618</a></li>
              {/* <li><a className="address"><span>LA Office:</span> 2609 W Beverly Blvd Suite 5, Montebello, California 90640</a></li> */}
            </div>
          </ul>
        </div>
        <div id='footer__social_media' className='col-2'>
          <ul className="footer_list"> <div className="footer_list--title">SOCIAL MEDIA</div> 
            <div className="footer_list_items--row">
              {
                social_media.map(({ name, link }) => <li>
                  <a className='social_media' href={link} target='_blank' rel='noopener noreferrer'>
                    <img src={`/imgs/social_icons/${name}.svg`} alt={name.charAt(0).toUpperCase() + name.slice(1)}/>
                  </a>
                </li>)
              }
            </div>
          </ul>
        </div>
      </div>
    </div>
  </div>
  }
}

export default Footer