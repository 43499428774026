import React, { Component } from 'react'
import PageContact from '../components/PageContact'
import ConstructionServices from '../components/ConstructionServices'
import ConstructionCustomer from '../components/CustomerCarousel/ConstructionCustomer'
import HeroConstruction from '../components/HeroConstruction'
import LazyLoad from 'react-lazyload';
import VideoSection from '../components/VideoSection'

export default class Construction extends Component {
    render() {
        return (
            <div id="construction-page" className='industries-page'>
                <LazyLoad height={200} offset={100} once >
                <HeroConstruction
                    title="CONSTRUCTION"
                    heroTitle="Incorporate technology with your workflow to"
                    heroTitle2="increase productivity and improve collaboration."
                />
                </LazyLoad>
                <LazyLoad height={200} offset={100} once >
                <ConstructionServices />
                <VideoSection
                    title='CorpTech Helps Vanguard Electronics Achieve Their Goals Despite COVID-19'
                    poster='/videos/posters/manufacturing_poster.png'
                    src='https://acdn.dnamicro.net/corporatech/manufacturing_industry_vanguard_electronics_case_study.mp4'
                />
                </LazyLoad>
                <div id="construction-customercard">
                    <ConstructionCustomer />
                </div>
                <LazyLoad>
                <PageContact />
                </LazyLoad>
            </div>
        )
    }
}
