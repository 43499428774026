import React, { Component } from 'react'
import PageContact from '../components/PageContact'
import LawServices from '../components/LawServices'
import LawCustomer from '../components/CustomerCarousel/LawCustomer'
import HeroLaw from '../components/HeroLaw'
import LazyLoad from 'react-lazyload';
import VideoSection from '../components/VideoSection'

export default class Law extends Component {
    render() {
        return (
            <div id="law-page" className='industries-page'>
                <LazyLoad height={200} offset={100} once >
                <HeroLaw
                    title="LAW"
                    heroTitle="Incorporate technology with your workflow to"
                    heroTitle2="automate tasks and secure confidential information."
                />
                </LazyLoad>
                <LazyLoad height={200} offset={100} once >
                <LawServices />
                <VideoSection
                    title='CorpTech Secures GoRentals Across 18 Locations'
                    poster='/videos/posters/goRentals_poster.png'
                    src='https://acdn.dnamicro.net/corporatetech/Go_Rentals_Testimonial.mp4'
                />
                </LazyLoad>
                <div id="law-customercard">
                    <LawCustomer />     
                </div>
                <LazyLoad height={200} offset={100} once >
                <PageContact />
                </LazyLoad>
            </div>
        )
    }
}
