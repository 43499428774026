import React, { Component } from 'react'



export default class GT_IDProtection extends Component {
    render() {

        const dash = Array(20).fill().map(c => <li className="icon dnaicon-dash"></li>)
        

        return (
            <div id="terms-page">
                <div className="dark-blue">
                    <h1 className="terms__title">Corporate Technology ID Protection Service Agreement</h1>
                </div>
                <div className="page_container">
                    <p className="terms__description--padding-small">By using the Corporate Technology services, you acknowledge that you have read our set of legal documents and agree to be bound by the Terms of Services contained herein as well as all acceptable use policies incorporated by reference.</p>
                    <h1 className="service__title--center">Corporate Technology ID Protection Service Agreement</h1>
                    <p className="under_title_justify">PLEASE READ CAREFULLY THE ID PROTECTION SERVICE AGREEMENT (FOR THE SAKE OF SIMPLICITY, FROM HEREON WE WILL REFER TO IT AS "AGREEMENT"). YOU AGREE WITH ALL TERMS OF SERVICE IN THIS AGREEMENT TO USE THE ID PROTECTION SERVICES (WHICH FROM HEREON WILL BE REFERRED TO AS "IDP SERVICES"). YOU AGREE THAT CORPORATE TECHNOLOGY CAN CHANGE THIS AGREEMENT BY POSTING THE CHANGED AGREEMENT ON THE WEB PAGE FROM WHERE YOU PURCHASED THE IDP SERVICES. YOU AGREE THAT ANY NEW, DIFFERENT, OR ADDITIONAL FEATURES THAT CHANGE THE IDP SERVICES WILL AUTOMATICALLY BE SUBJECT TO THIS AGREEMENT. YOU AGREE THAT ALL NEW, UPDATED OR ADDITIONAL FEATURES THAT CHANGE IN SOME WAY THE IDP SERVICES WILL AUTOMATICALLY BE SUBJECT TO THIS AGREEMENT. IF YOU DO NOT AGREE WITH THE TERMS OF SERVICE OF THIS AGREEMENT AND ALL THE CHANGES TO THE AGREEMENT HERETO, DO NOT USE OR CONTINUE USING THE IDP SERVICES. IF YOU CONTINUE TO USE THE IDP SERVICES AFTER THIS AGREEMENT HAS BEEN UPDATED AND THE CHANGES POSTED ONLINE, YOU WILL AUTOMATICALLY BE SUBJECT TO THE NEW TERMS OF SERVICE POSTED.</p>
                    <p>This Agreement is by and between Corporate Technology, a US company headquartered in California, and You, Your heirs, executors, administrators, agents, successors, and assigns ("You," "Your," and other appropriate formatives). This Agreement will define the exact Terms of Service of Your relationship with Corporate Technology and Your use of the IDP Services. You confirm that You have read and understand and agree to be bound by all Terms of Service specified in this Agreement for transactions entered into by:</p>
                        <ol>
                            <li>You on Your behalf;</li>
                            <li>anyone acting as Your agent or representative;</li>
                            <li>anyone who uses the account from which You want to obtain the IDP Services, whether or not the transactions are on Your behalf and/or authorized by You.</li>
                        </ol>
                    <p>You agree that You will be bound by representations made by third parties You use to obtain the IDP Services. You also agree to follow the Terms of Service, established by the Internet Corporation for Assigned Names and Numbers ("ICANN"), including the Uniform Domain Name Dispute Resolution Policy and any policy which ICANN may enforce, regarding WHOIS information</p>
                    <div className="container-divider">
                        <ul>
                            {dash}
                        </ul>
                    </div>
                    <div className="list-container">
                        <dl className='terms_individual_details'>
                            <dt>1. THE IDP SERVICES</dt>
                                <dd>If you subscribe to the IDP Services, your name, postal and email address, phone, and fax numbers shall be kept confidential and hidden, falling subject to Section 5 of this Agreement. The following information (instead of your personal details) will be thereafter publicly available in the "Whois" section for each IDP Domain:</dd>
                                    <ul>
                                        <li>Corporate Technology's email and postal address, phone and facsimile number for the registrant, administrative, technical and billing contacts ("IDP Addresses");</li>
                                        <li>The primary and secondary domain name servers You have designated for the IDP Domain;</li>
                                        <li>The IDP Domain's original registration and expiration date;</li>
                                        <li>The identity of Your registrar and the status of the IDP Domain with the registrar (such as "Active", "Registrar Lock", "ClientTransferProhibited", etc.).</li>
                                    </ul>
                                <dd>While You will not be listed as the registrant of the IDP Domain, Corporate Technology will not control the IDP Domain(s). You will retain your right to sell or transfer each IDP Domain; You will retain your right to control and set the DNS settings for the IDP Domain(s); You will retain your right to renew each IDP Domain name registration upon expiration and You will still be responsible for all monetary or other legal claims, connected with Your IDP Domain(s), subject to the remaining sections of this Agreement.</dd>
                                <dd><b>IMPORTANT: THE IDP SERVICE IS NOT A MAIL FORWARDING SERVICE. </b> You agree that You will not give to any third party the IDP Addresses for the intention of having the third party send communications to you via the Corporate TechnologyIDP Service. Third parties can obtain the IDP Addresses listed in the Whois Information of their own accord, but You agree that You will not provide the IDP Addresses to such third parties.</dd>
                            <dt>2. PROVISION OF PERSONAL INFORMATION</dt>
                                <dd>You agree that for each IDP Domain, You will provide Corporate Technology with the current details and that you will maintain them current and accurate:</dd>
                                    <ul>
                                        <li>Your name, address, email and postal address, phone, and fax numbers;</li>
                                        <li>The name, address, email and postal address, phone, and fax numbers for the IDP Domain's administrative, technical, and billing contacts;</li>
                                        <li>The primary and secondary domain name servers for the IDP Domain;</li>
                                    </ul>
                                <dd>You agree to:</dd>
                                    <ul>
                                        <li>update this information as soon as it changes;</li>
                                        <li>reply in up to five (5) business days to any inquiries made by Corporate Technology to determine the validity of the information You have provided;</li>
                                        <li>promptly reply to messages regarding correspondence sent to or involving Your IDP Domain(s), as defined in section 6 below.</li>
                                    </ul>
                                <dd>Corporate Technology may modify its information from time to time in such a way, which may constitute a "Change of Registrant" under ICANN’s Transfer Policy (the "Transfer Policy"). In such a case, You explicitly opt-out of any 60-day inter-registrar transfer lock that would otherwise be imposed under the Transfer Policy due to any such Change of Registrant.</dd>
                                <dd>In addition, You explicitly authorize the registrar of the IDP Domain to act as its "Designated Agent" (as defined in the Transfer Policy) to approve each "Change of Registrant" (as defined in the Transfer Policy) on its behalf.</dd>
                            <dt>3. FEES AND RENEWALS</dt>
                                <dd>If, on the due date, Corporate Technology has not received a renewal or other fees, you agree that Corporate Technology can try to contact You, but is not obliged to do so, and You agree that Corporate Technology can suspend or terminate the IDP Services as a result of non-payment.</dd>
                                <dd>Corporate Technology can charge reasonable fees for administrative tasks for services, not falling into the already defined ones. These include, but are not limited to, customer service issues that require personal service and disputes that require legal services. You are responsible for covering all fees and taxes associated with using Corporate Technology's services. Payments shall be made by credit card or PayPal. You agree that charges will appear on Your credit card statement under "PayPal Hosting" or "PayPal Hosting and Domains", depending on whether the credit card statement is restricted to 11 or 19 symbols. All fees are non-refundable.</dd>
                                <dd>If there is a chargeback for a fee, which has been already covered, You agree that Corporate Technology can, without notice, pursue all available remedies to obtain payment for that fee. Without limitation on other remedies which may be available under such circumstances, You agree that Corporate Technology may assume complete ownership of the IDP Domain(s), that the IDP Domain(s) can be sold to third parties, or that the IDP Domain(s) can be pointed to an IP address of Corporate Technology's own choice, and that Corporate Technology can immediately cancel Your Account and all other services provided to You.</dd>
                                <dd>IMPORTANT TERMS REGARDING THE RELATIONSHIP OF THE TERM OF THE IDP SERVICES AND THE REGISTRATION TERM OF THE ASSOCIATED IDP DOMAIN: Your IDP Services term begins on the date when Your purchase of IDP Services is accepted by the Corporate Technology and it will run for the period you have chosen (by default, one year). The IDP Services term can be different from the registration term of the IDP Domain. If the domain, for which the IDP Service is activated, expires and is deleted before the end of the term of the IDP Service, then the IDP Service associated with this domain will end when the IDP Domain is deleted and there will be no refund for the unused portion of the IDP Services. If the IDP Services term ends while the registration term for the IDP Domain is still ongoing, then the IDP Services will no longer be available until they are renewed and the personal information specified in section 2 will be listed as the WHOIS information for the (then former) IDP Domain. All communication forwarding services (specified in section 6) will no longer be provided.</dd>
                            <dt>4. YOUR RESPONSIBILITIES AND WARRANTIES</dt>
                                <dd>It's Your responsibility to keep all information provided by You in relation to this Agreement truthful, complete, current, and accurate and to maintain all information in this status for the term of this Agreement. You agree that You will be using the IDP service in good faith and that You have no information, knowledge, or reason to consider that Your IDP Domain or the content found at all the IP Addresses associated with it violate or conflict with legal rights of a third party or a third party's trademark or trade name. You warrant that neither the IDP Service nor the domain associated with the IDP Service is to be used in connection with any of the activities defined in section 5 of this Agreement or used for the transmission of unsolicited Spam emails.</dd>
                            <dt>5. SERVICE DISCONTINUANCE</dt>
                                <dd>In the event of any of the following:</dd>
                                    <ul>
                                        <li>If the IDP Domain(s) is (are) alleged to violate any third party's legal rights, trademark, copyright interests or trade name;</li>
                                        <li>If You violate any section of this Agreement;</li>
                                        <li>If You breach any section of the Domain Registration Agreement;</li>
                                        <li>If necessary to comply with any applicable laws, government rules or requirements, subpoenas, court orders or law enforcement requests;</li>
                                        <li>If Corporate Technology is named as a defendant in, or investigated in anticipation of, any legal or administrative proceeding arising out of Your use of the IDP Services or an IDP Domain;</li>
                                        <li>If necessary to comply with ICANN's Dispute Resolution Policy or other policies enforced by ICANN (including policies which may preclude using a service such as ID Protect);</li>
                                        <li>If necessary to avoid any financial loss or legal liability (civil or criminal) on the part of Corporate Technology, its parent companies, subsidiaries, affiliates, shareholders, agents, officers, directors, and employees;</li>
                                        <li>OR if it comes to Corporate Technology's attention that You are alleged to be using the IDP Services for the purpose of engaging in, participating in, sponsoring, or hiding Your involvement in illegal or morally objectionable activities, including but not limited to, activities which are designed, intended to or otherwise:</li>
                                            <ol>
                                                <li>appeal primarily to prurient interests;</li>
                                                <li>defame, embarrass, harm, abuse, threaten, or harass;</li>
                                                <li>violate state or federal laws of the United States and/or foreign territories;</li>
                                                <li>involve hate crimes, terrorism or child pornography;</li>
                                                <li>are tortuous, vulgar, obscene, invasive of a third party's privacy, race, ethnicity, or are otherwise objectionable;</li>
                                                <li>impersonate the identity of a third party;</li>
                                                <li>harm minors in any way;</li>
                                                <li>relate to or transmit viruses, Trojan Horses, access codes, backdoors, worms, timebombs or any other code, routine, mechanism, device or item that corrupts, damages, impairs, interferes with, intercepts or misappropriates any software, hardware, firmware, network, system, data or personally identifiable information,</li>
                                            </ol>
                                    </ul>
                                <dd>THEN</dd>
                                <dd>You understand and agree that Corporate Technology has the absolute right and power, in its sole discretion and without any liability to You whatsoever, to suspend the IDP Services, close Your Account, terminate the provision of the IDP Services, list the information You provided in section 2 as the Whois information or provide the information You provided in section 2 to a claimant, resolve all third party claims, whether threatened or made, arising out of Your use of the IDP Domain(s), or take any other action which Corporate Technologydeems necessary.</dd>
                                <dd>In the event Corporate Technology takes any of the actions set forth above or in the event You elect to cancel the IDP Services for any reason, any fees paid by You for the IDP Services will NOT be refunded.</dd>
                                <dd>You also agree and understand that Corporate Technology can, in its sole discretion and with no liability to You, cancel the IDP Service during the first thirty (30) days after Your purchase of the IDP Services, and/or suspend all Your rights under this Agreement and display the original WHOIS information until a resolution of the dispute or investigation of allegations raised against You is reached.</dd>
                            <dt>6. FORWARDING COMMUNICATIONS</dt>
                                <dd>You agree and understand that Corporate Technology has the right to review communications sent to the IDP Addresses associated with Your IDP Domain. For communications received via certified or traceable courier mail (such as UPS, FedEx, or DHL), or first-class U.S. postal mail which does not appear to be unsolicited commercial mail ("Spam"), Corporate Technology will either i) forward such communication to You or ii) attempt to send you a scanned copy of the communication to consult with You about whether to forward the communication to You or not. You specifically acknowledge that Corporate Technology may choose not to contact You regarding (and not forward to You) first class postal mail or email, fax, postal mail, or telephone communications which appear to be unsolicited communications which offer or advertise the sale of goods or services or which solicit charitable contributions or communications which appear to arise from Your having used the IDP Services as a general mail forwarding service (see section 1, above). You authorize Corporate Technology to either discard and ignore all such communications or return them to the sender unopened. You agree to waive all claims arising from Your failure to receive communications directed to Your domain name but not forwarded to You by Corporate Technology, including failures that arise from Corporate Technology's mistake in judging whether a communication appears to be an unsolicited communication.</dd>
                                <dd>The Whois directory generally requires an email address for every purchased domain name registration. When You purchase IDP Services, Corporate Technology creates an email address for that domain, which will look like this - "dsa343wqef432@whois-shield.com". From there on, when a message is sent to the email address listed as the IDP Address, Corporate Technology will forward the messages to the email address You listed in section 2. If the email address you have provided us with becomes non-functioning or if an email sent to the address bounces, Corporate Technology is not bound to try to contact You through other means.</dd>
                                <dd>Your Obligation to Reply to messages from Corporate Technology:</dd>
                                    <ul>
                                        <li>If Corporate Technologyreceives a communication (email or general mail) which may warrant if forwarded to You, Corporate Technology will send an email to the email address You provided as Your personal email address when filling the domain's Whois information. This email message will identify the sender of the message and the date received. You will have seventy-two (72) hours to decide whether to have the message(s) forwarded to you. If you do not respond within this period, the communication(s) will not be forwarded and discarded. The method of forwarding will be determined by Corporate Technology. Forwarding of the messages may not be immediate; there can be a delay and communication(s) may be aggregated to be forwarded together, if there are a lot of them received. Corporate Technology can begin charging fees for forwarding communications or may change the method of forwarding the correspondence without notice;</li>
                                        <li>If You do not respond to the messages sent from Corporate Technologyregarding communications received at the IDP Address, Corporate Technology may immediately reveal the information You provided as the original Whois data and/or cancel the IDP Services. This means that Whois lookups will display Your name, postal address, email address, and phone number. This action will be taken because Corporate Technology will not get involved in legal or other matters between You and third parties.</li>
                                    </ul>
                            <dt>7. LIMITATION OF LIABILITY</dt>
                                <dd>Corporate Technology shall not be liable under any circumstances for any special, consequential, incidental, or exemplary damages arising out of or in any way connected with this agreement or the domain name registration services, including but not limited to: damages for lost profits, loss of use, lost data, loss of privacy, damages to third parties. This limited liability clause shall apply even if Corporate Technology has been notified of the possibility of any claims. In no event shall Corporate Technology's maximum liability exceed the total amount paid by you for the service or the product. Corporate Technology's liability is limited to the extent permitted by law in states that do not allow the exclusion or limitation of liability for consequential or incidental damages.</dd>
                                <dd>You also understand and agree that Corporate Technology will disclaim any loss or liability resulting from:</dd>
                                    <ul>
                                        <li>the inadvertent disclosure or theft of Your personal information;</li>
                                        <li>access delays or interruptions to our web site or the web sites of affiliated parties;</li>
                                        <li>data non-delivery or misdelivery between You and Corporate Technology;</li>
                                        <li>the failure for whatever reason to renew the IDP Services;</li>
                                        <li>the unauthorized use of Your Account or any of Corporate Technology's services;</li>
                                        <li>deletion of, failure to store, failure to process or act upon email messages sent to or forwarded to either You or the email address listed for Your IDP Domain;</li>
                                        <li>processing of updated information regarding Your Account;</li>
                                        <li>any act or omission caused by You or Your agents (whether authorized by You or not).</li>
                                    </ul>
                                <dd>You also agree to release, defend, indemnify and hold harmless Corporate Technology and its parent companies, subsidiaries, affiliates, shareholders, agents, directors, officers, and employees from and against all claims, demands, liabilities, losses, damages, or costs, including reasonable attorney's fees, coming from or related in any way to this Agreement, the IDP Services, the web sites of the Service Providers, Your Account, and/or Your use of Your IDP Domain.</dd>
                            <dt>8. TERM OF AGREEMENT</dt>
                                <dd>The term of this Agreement begins on the date Your purchase of the IDP Services is accepted by Corporate Technology. The term shall run for a period for which You ordered the IDP Services unless terminated or suspended sooner according to the terms of this Agreement. Sections 6 (Forwarding Communications) and 7 (Limitation of Liability) shall survive any termination or expiration of this Agreement.</dd>
                            <dt>9. CONTACT DETAILS</dt>
                                <dd>If you have any questions about our ID Protection Service Agreement, please use our  <a href="https://www.corporatetech.com/contact-us">Contact Us Form.</a></dd>
                        </dl>
                    </div>
                </div>
            </div>
        )
    }
}
