import React, { Component } from 'react'
import PageContact from '../components/PageContact'
import MedicalServices from '../components/MedicalServices'
import MedicalCustomer from '../components/CustomerCarousel/MedicalCustomer'
import HeroMedical from '../components/HeroMedical'
import LazyLoad from 'react-lazyload';
import VideoSection from '../components/VideoSection'

export default class Medical extends Component {
    render() {
        return (
            <div id="medical-page" className='industries-page'>
                <LazyLoad height={200} offset={100} once >
                <HeroMedical
                    title="MEDICAL/DENTAL"
                    heroTitle="Incorporate technology with your workflow to"
                    heroTitle2="deliver efficient healthcare services on time."
                />
                </LazyLoad>
                <LazyLoad height={200} offset={100} once >
                <MedicalServices />
                <VideoSection
                    title='Asher Fabrics Embraces CorpTech Solutions to Optimize Growth'
                    poster='/videos/posters/asherCaffe_poster.png'
                    src='https://acdn.dnamicro.net/corporatetech/Asher_Caffe_Testimonial_Video.mp4'
                />
                </LazyLoad>
                <div id="medical-customercard">
                    <MedicalCustomer />
                </div>
                <LazyLoad height={200} offset={100} once >
                <PageContact />
                </LazyLoad>
            </div>
        )
    }
}
