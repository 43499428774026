import HomePage from '../pages/HomePage'
import Retail from '../pages/Retail'
import Manufacturing from '../pages/Manufacturing'
import Corporate from '../pages/Corporate'
import Airport from '../pages/Airport'
import Finance from '../pages/Finance'
import Law from '../pages/Law'
import Government from '../pages/Government'
import Construction from '../pages/Construction'
import Medical from '../pages/Medical'
import About_us from '../pages/About_us'
import ContactUs from '../pages/ContactUs'
import NotFound from '../pages/404page'
import ItDepartment from '../pages/ItDepartment'
import SecurityCompliance from '../pages/SecurityCompliance'
import SoftDev from '../pages/SoftDev'
import TermsOfService from '../pages/TermsOfService'
import WebHosting from '../components/TermsOfServices/WebHosting/WebHosting'
import WH_AcceptablePolicy from '../components/TermsOfServices/WebHosting/AcceptablePolicy'
import WH_ServiceLevel from '../components/TermsOfServices/WebHosting/ServiceLevel'
import SD_AcceptableUsePolicy from '../components/TermsOfServices/Semi-dedicatedServer/SD_AcceptableUsePolicy'
import SD_ServerTerms from '../components/TermsOfServices/Semi-dedicatedServer/SD_ServerTerms'
import SD_ServiceGuarantees from '../components/TermsOfServices/Semi-dedicatedServer/SD_ServiceGuarantees'
import DS_ColocationTelecommunication from '../components/TermsOfServices/DedicatedServer/DS_ColocationTelecommunication'
import DS_DedicatedServer from '../components/TermsOfServices/DedicatedServer/DS_DedicatedServer'
import DS_DedicatedServiceGuarantee from '../components/TermsOfServices/DedicatedServer/DS_DedicatedServiceGuarantee'
import VPS_AcceptableUse from '../components/TermsOfServices/VirtualPrivateService/VPS_AcceptableUse'
import VPS_ServiceLevel from '../components/TermsOfServices/VirtualPrivateService/VPS_ServiceLevel'
import VPS_Terms from '../components/TermsOfServices/VirtualPrivateService/VPS_terms'
import GT_CancellationRefund from '../components/TermsOfServices/GeneralTerms/GT_CancellationRefund'
import GT_DomainRegistration from '../components/TermsOfServices/GeneralTerms/GT_DomainNameRegistration'
import GT_DomainResolution from '../components/TermsOfServices/GeneralTerms/GT_DomainNameResolution'
import GT_IDProtection from '../components/TermsOfServices/GeneralTerms/GT_IDProtection'
import GT_OrderVerification from '../components/TermsOfServices/GeneralTerms/GT_OrderVerification'
import GT_PrivacyPolicy from '../components/TermsOfServices/GeneralTerms/GT_PrivacyPolicy'
import DS_AcceptableUSePolicy from '../components/TermsOfServices/DedicatedServer/DS_AcceptableUsePolicy'


export const mainMenu = [
  { name: 'Meet the Team', path: '/About-us' },
  { name: 'Terms of Service', path: '/terms-of-service' },
  { name: 'Contact us', path: '/contact-us' }
]

export const pages = [
  {
    path: '/',
    Component: HomePage,
  },
  {
    path: '/manufacturing',
    Component: Manufacturing,
    name: "Manufacturing",
    parent: 'Industries'
  },
  {
    path: '/corporate',
    Component: Corporate,
    name: "Corporate",
    parent: 'Industries'
  },
  {
    path: '/airport',
    Component: Airport,
    name: "Airport",
    parent: 'Industries'
  },
  {
    path: '/retail',
    Component: Retail,
    name: "Retail",
    parent: 'Industries'
  },
  {
    path: '/finance',
    Component: Finance,
    name: "Finance",
    parent: 'Industries'
  },
  {
    path: '/law',
    Component: Law,
    name: "Law",
    parent: 'Industries'
  },
  {
    path: '/government',
    Component: Government,
    name: "Government and Education",
    parent: 'Industries'
  },
  {
    path: '/construction',
    Component: Construction,
    name: "Construction",
    parent: 'Industries'
  },
  {
    path: '/medical',
    Component: Medical,
    name: "Medical/Dental",
    parent: 'Industries'
  },
  {
    path: '/about-us',
    Component: About_us,
    parent: 'Meet the Team'
  },
  {
    path: '/contact-us',
    Component: ContactUs,
    name: 'Contact Us',
    parent: 'Contact Us'
  },
  {
    path: '/it-management',
    Component: ItDepartment,
    name: 'IT Management',
    parent: 'Services'
  },
  {
    path: '/cyber-security-compliance',
    Component: SecurityCompliance,
    name: 'Cyber Security and Compliance',
    parent: 'Services'
  },
  {
    path: '/software-development',
    Component: SoftDev,
    name: 'Software Development',
    parent: 'Services'
  },
  {
    path: '/terms-of-service',
    Component: TermsOfService,
    name: 'Terms of Service',
    parent: 'Terms of Service'
  },
  {
    path: '/hosting-terms',
    Component: WebHosting,
    name: 'Web Hosting Service',
    parent: 'Terms of Service'
  },
  {
    path: '/acceptable-use-policy',
    Component: WH_AcceptablePolicy,
    name: 'Acceptable use Policy',
    parent: 'Terms of Service'
  },
  {
    path: '/service-level-guarantee',
    Component: WH_ServiceLevel,
    name: 'Service Level Guarantee',
    parent: 'Terms of Service'
  },
  {
    path: '/semi-dedicated-acceptable-use-policy',
    Component: SD_AcceptableUsePolicy,
    name: 'Semi Dedicated Acceptable Use Policy',
    parent: 'Terms of Service'
  },
  {
    path: '/semi-dedicated-server-terms',
    Component: SD_ServerTerms,
    name: 'Semi Dedicated Server Terms',
    parent: 'Terms of Service'
  },
  {
    path: '/semi-dedicated-service-guarantee',
    Component: SD_ServiceGuarantees,
    name: 'Semi Dedicated Service Guarantee',
    parent: 'Terms of Service'
  },
  {
    path: '/dedicated-server-colocation-telecommunication',
    Component: DS_ColocationTelecommunication,
    name: 'Dedicated Server Colocation and Telecommunication',
    parent: 'Terms of Service'
  },
  {
    path: '/dedicated-server',
    Component: DS_DedicatedServer,
    name: 'Dedicated Server',
    parent: 'Terms of Service'
  },
  {
    path: '/dedicated-server-acceptable-use-policy',
    Component: DS_AcceptableUSePolicy,
    name: 'Dedicated Server Acceptable Use Policy'
  },
  {
    path: '/dedicated-server-service-guarantee',
    Component: DS_DedicatedServiceGuarantee,
    name: 'Dedicated Server Service Guarantee',
    parent: 'Terms of Service'
  },
  {
    path: '/virtual-private-acceptable-use',
    Component: VPS_AcceptableUse,
    name: 'Virtual Private Acceptable Use',
    parent: 'Terms of Service'
  },
  {
    path: '/virtual-private-service-level',
    Component: VPS_ServiceLevel,
    name: 'Virtual Private Service Level',
    parent: 'Terms of Service'
  },
  {
    path: '/virtual-private-terms',
    Component: VPS_Terms,
    name: 'Virtual Private Terms',
    parent: 'Terms of Service'
  },
  {
    path: '/general-terms-cancellation-refund',
    Component: GT_CancellationRefund,
    name: 'General Terms Cancellation and Refund',
    parent: 'Terms of Service'
  },
  {
    path: '/general-terms-domain-registration',
    Component: GT_DomainRegistration,
    name: 'General Terms Domain Name Registration',
    parent: 'Terms of Service'
  },
  {
    path: '/general-terms-domain-resolution',
    Component: GT_DomainResolution,
    name: 'General Terms Domain Name Resolution',
    parent: 'Terms of Service'
  },
  {
    path: '/general-terms-id-protection',
    Component: GT_IDProtection,
    name: 'General Terms ID Protection',
    parent: 'Terms of Service'
  },
  {
    path: '/general-terms-order-verification',
    Component: GT_OrderVerification,
    name: 'General Terms Order Verification',
    parent: 'Terms of Service'
  },
  {
    path: '/general-terms-privacy-policy',
    Component: GT_PrivacyPolicy,
    name: 'General Terms Privacy Policy',
    parent: 'Terms of Service'
  },
  {
    path: '*',
    Component: NotFound,
  },
]
// export const industries = [
//   'Airport',
//   'Medical/Dental',
//   'Government and Education',
//   'Law',
//   'Corporate',
//   'Finance',
//   'Retail',
//   'Construction',
//   'Manufacturing'
// ]
export const industriesPage = [
  'airport',
  'medical',
  'government',
  'retail',
  'manufacturing',
  'finance',
  'corporate',
  'law'
]

export const industries = [
  {
    title: 'Airport',
    link: 'airport'
  },
  {
    title: 'Corporate',
    link: 'corporate'
  },
  {
    title: 'Medical',
    link: 'medical'
  },
  {
    title: 'Law',
    link: 'law'
  },
  {
    title: 'Government',
    link: 'government'
  },
  {
    title: 'Finance',
    link: 'finance'
  },
  {
    title: 'Construction',
    link: 'construction'
  },
  {
    title: 'Retail',
    link: 'retail'
  },
  {
    title: 'Manufacturing',
    link: 'manufacturing'
  }
]
export const card = [
  {
    title: 'Lower My IT Costs',
    description: '"Is it possible to cut down IT costs without compromising operations?"',
    image: '/imgs/customers/itcost.png'
  },
  {
    title: 'Simplifying IT',
    description: '"I want to better understand how IT works."',
    image: '/imgs/customers/simplifying.png'
  },
  {
    title: 'Workflow Efficiency',
    description: '"We want to get a platform that can provide a streamlined process."',
    image: '/imgs/customers/workflow.png'
  },
  {
    title: 'IT Services',
    description: '"Can Managed IT Services cope with changing technological trends?"',
    image: '/imgs/customers/itservices.png'
  },
  {
    title: 'Data Backup and Disaster Recovery',
    description: '"Is regular backup data available?"',
    image: '/imgs/customers/backup.png'
  },
  {
    title: 'VOIP Services',
    description: '"Do I need dedicated bandwith?"',
    image: '/imgs/customers/voip.png'
  },
  {
    title: 'Network and Cybersecurity Services',
    description: '"Do we have maximum protection against cyberattacks or threats?"',
    image: '/imgs/customers/cybersecurity.png'
  },
  {
    title: 'Cloud Services',
    description: '"How secure is our data with cloud services?"',
    image: '/imgs/customers/cloud.png'
  },
  {
    title: 'Business Continuity Strategies',
    description: '"I want to understand the recovery strategies in case of a disaster."',
    image: '/imgs/customers/business.png'
  },
  {
    title: 'Printers',
    description: '"How can I better manage my printing needs?"',
    image: '/imgs/customers/printers.png'
  },
]
export const customercard = [
  { image: '/imgs/Logo_Customers/home/acht.png' },
  { image: '/imgs/Logo_Customers/home/usbank.png' },
  { image: '/imgs/Logo_Customers/home/calistate.png' },
  { image: '/imgs/Logo_Customers/home/carbon.png' },
  { image: '/imgs/Logo_Customers/home/dealerclick.png' },
  { image: '/imgs/Logo_Customers/home/drshida.png' },
  { image: '/imgs/Logo_Customers/home/fashionnova.png' },
  { image: '/imgs/Logo_Customers/home/fidelity.png' },
  { image: '/imgs/Logo_Customers/home/gorentals.png' },
  { image: '/imgs/Logo_Customers/home/lax.png' },
  { image: '/imgs/Logo_Customers/home/liquipel.png' },
  { image: '/imgs/Logo_Customers/home/ljp.png' },
  { image: '/imgs/Logo_Customers/home/nutall.png' },
  { image: '/imgs/Logo_Customers/home/caliclosets.png' },
  { image: '/imgs/Logo_Customers/home/sharp.png' },
  { image: '/imgs/Logo_Customers/home/vanguard.png' },
  { image: '/imgs/Logo_Customers/home/walkow.png' },
  { image: '/imgs/Logo_Customers/home/amscope.png' },
  { image: '/imgs/Logo_Customers/home/algertriton.png' },
  { image: '/imgs/Logo_Customers/home/armaghan.png' },
  { image: '/imgs/Logo_Customers/home/asher.png' },
  { image: '/imgs/Logo_Customers/home/benji.png' },
  { image: '/imgs/Logo_Customers/home/cardinaldental.png' },
  { image: '/imgs/Logo_Customers/home/cd3k.png' },
  { image: '/imgs/Logo_Customers/home/claraphi.png' },
  { image: '/imgs/Logo_Customers/home/dnamicro.png' },
  { image: '/imgs/Logo_Customers/home/dreamcatchers.png' },
  { image: '/imgs/Logo_Customers/home/dun.png' },
  { image: '/imgs/Logo_Customers/home/firstoption.png' },
  { image: '/imgs/Logo_Customers/home/fullerton.png' },
  { image: '/imgs/Logo_Customers/home/karaki.png' },
  { image: '/imgs/Logo_Customers/home/liberty.png' },
  { image: '/imgs/Logo_Customers/home/magnetika.png' },
  { image: '/imgs/Logo_Customers/home/mark.png' },
  { image: '/imgs/Logo_Customers/home/morson.png' },
  { image: '/imgs/Logo_Customers/home/northwestern.png' },
  { image: '/imgs/Logo_Customers/home/paco.png' },
  { image: '/imgs/Logo_Customers/home/rubicon.png' },
  { image: '/imgs/Logo_Customers/home/scte.png' },
  { image: '/imgs/Logo_Customers/home/sg.png' },
  { image: '/imgs/Logo_Customers/home/siladental.png' },
  { image: '/imgs/Logo_Customers/home/spectrum.png' },
  { image: '/imgs/Logo_Customers/home/stadco.png' },
  { image: '/imgs/Logo_Customers/home/timewarner.png' },
  { image: '/imgs/Logo_Customers/home/tow.png' },
  { image: '/imgs/Logo_Customers/home/vizio.png' },
]

export const airportCustomers = [
  { image: '/imgs/Logo_Customers/Airport/Group 4738.png' },
  { image: '/imgs/Logo_Customers/Airport/Group 4740.png' },
  { image: '/imgs/Logo_Customers/Airport/Group 4741.png' },
  { image: '/imgs/Logo_Customers/Airport/Group 4742.png' },
  { image: '/imgs/Logo_Customers/Airport/Group 4743.png' },
]

export const retailCustomers = [
  { image: '/imgs/Logo_Customers/Retail/Group 4738.png' },
  { image: '/imgs/Logo_Customers/Retail/Group 4740.png' },
  { image: '/imgs/Logo_Customers/Retail/Group 4742.png' },
  { image: '/imgs/Logo_Customers/Retail/Group 4743.png' },
  { image: '/imgs/Logo_Customers/Retail/Group 4744.png' },
  { image: '/imgs/Logo_Customers/Retail/Group 4745.png' },
  { image: '/imgs/Logo_Customers/Retail/Group 4746.png' },
]

export const corporateCustomers = [
  { image: '/imgs/Logo_Customers/Corporate/Group 4737.png' },
  { image: '/imgs/Logo_Customers/Corporate/vizio.png' },
  { image: '/imgs/Logo_Customers/Corporate/vanguard.png' },
  { image: '/imgs/Logo_Customers/Corporate/Group 4738.png' },
  { image: '/imgs/Logo_Customers/Corporate/Group 4739.png' },
  { image: '/imgs/Logo_Customers/Corporate/Group 4740.png' },
  { image: '/imgs/Logo_Customers/Corporate/Group 4741.png' },
  { image: '/imgs/Logo_Customers/Corporate/Group 4742.png' },
  { image: '/imgs/Logo_Customers/Corporate/Group 4743.png' },
]

export const financeCustomers = [
  { image: '/imgs/Logo_Customers/Finance/Group 4737.png' },
  { image: '/imgs/Logo_Customers/Finance/Group 4738.png' },
  { image: '/imgs/Logo_Customers/Finance/Group 4739.png' },
  { image: '/imgs/Logo_Customers/Finance/Group 4740.png' },
  { image: '/imgs/Logo_Customers/Finance/Group 4741.png' },
  { image: '/imgs/Logo_Customers/Finance/Group 4742.png' },
]

export const constructionCustomers = [
  { image: '/imgs/Logo_Customers/Construction/Group 4737.png' },
  { image: '/imgs/Logo_Customers/Construction/Group 4739.png' },
  { image: '/imgs/Logo_Customers/Construction/Group 4741.png' },
  { image: '/imgs/Logo_Customers/Construction/Group 4743.png' },
  { image: '/imgs/Logo_Customers/Construction/Group 4744.png' },
  { image: '/imgs/Logo_Customers/Construction/Group 4745.png' },
]

export const governmentCustomers = [
  { image: '/imgs/Logo_Customers/Government/Group 4737.png' },
  { image: '/imgs/Logo_Customers/Government/Group 4738.png' },
  { image: '/imgs/Logo_Customers/Government/Group 4739.png' },
  { image: '/imgs/Logo_Customers/Government/Group 4740.png' },
]

export const lawCustomers = [
  { image: '/imgs/Logo_Customers/Law/Group 4675.png' },
  { image: '/imgs/Logo_Customers/Law/Group 4676.png' },
  { image: '/imgs/Logo_Customers/Law/Group 4677.png' },
]

export const medicalCustomers = [
  { image: '/imgs/Logo_Customers/Medical/Group 4737.png' },
  { image: '/imgs/Logo_Customers/Medical/Group 4738.png' },
  { image: '/imgs/Logo_Customers/Medical/Group 4739.png' },
  { image: '/imgs/Logo_Customers/Medical/Group 4740.png' },
  { image: '/imgs/Logo_Customers/Medical/Group 4741.png' },
  { image: '/imgs/Logo_Customers/Medical/Group 4742.png' },
  { image: '/imgs/Logo_Customers/Medical/Group 4743.png' },
  { image: '/imgs/Logo_Customers/Medical/Group 4744.png' },
]

export const manufacturingCustomers = [
  { image: '/imgs/Logo_Customers/Manufacturing/Group 4737.png' },
  { image: '/imgs/Logo_Customers/Manufacturing/vizio.png' },
  { image: '/imgs/Logo_Customers/Manufacturing/vanguard.png' },
  { image: '/imgs/Logo_Customers/Manufacturing/Group 4743.png' },
  { image: '/imgs/Logo_Customers/Manufacturing/Group 4738.png' },
  { image: '/imgs/Logo_Customers/Manufacturing/Group 4739.png' },
  { image: '/imgs/Logo_Customers/Manufacturing/Group 4740.png' },
  { image: '/imgs/Logo_Customers/Manufacturing/Group 4741.png' },
  { image: '/imgs/Logo_Customers/Manufacturing/Group 4745.png' },
  { image: '/imgs/Logo_Customers/Manufacturing/Group 4746.png' },
]

// RETAIL 
export const retailData = [
  {
    keyservices: 'ERP System Integration',
    icon: 'line-space icon dnaicon-erp'
  },
  {
    keyservices: 'Business Continuity and Disaster Recovery',
    icon: 'line-space icon dnaicon-arrow-settings'
  },
  {
    keyservices: 'PCI Compliance',
    icon: 'line-space icon dnaicon-pci'
  },
  {
    keyservices: 'App Development',
    icon: 'line-space icon dnaicon-mobile-appdev'
  },
  {
    keyservices: 'Compliance Auditing and Management (SOC2, PCI, NIST)',
    icon: 'line-space icon dnaicon-soc2-logo'
  },
  {
    keyservices: 'Streamline Documentation and Communication through Departments',
    icon: 'line-space icon dnaicon-documentation'
  },
  {
    keyservices: 'Asset and IT System Management',
    icon: 'line-space icon dnaicon-asset-it-management'
  },
  {
    keyservices: 'Prevention in Network Outages and Interruptions',
    icon: 'line-space icon dnaicon-prevention'
  },
  {
    keyservices: 'Cybersecurity and Data Breach Protection',
    icon: 'line-space icon dnaicon-cybersecurity-protection'
  },
  {
    keyservices: 'Remote Payment and E-Commerce',
    icon: 'line-space icon dnaicon-remote'
  },
]

export const meetTheTeam = [
  {
    image: '/imgs/team/nima.png',
    name: 'Nima Sajadi',
    position: 'CEO/President'
  },
  {
    image: '/imgs/team/kaz.png',
    name: 'Kaz Rezvan',
    position: 'Director of IT'
  },
  {
    image: '/imgs/team/alex.png',
    name: 'Alex Bohatka',
    position: 'Director of Business Development'
  },
  {
    image: '/imgs/team/luka.png',
    name: 'Luka Radisic',
    position: 'Senior Engineer'
  },
  {
    image: '/imgs/team/freddy.png',
    name: 'Freddy Shamloo',
    position: 'Help Desk Manager'
  },
  {
    image: '/imgs/team/micah.png',
    name: 'Micah Trafemich',
    position: 'Senior Engineer'
  },
  {
    image: '/imgs/team/refugio.png',
    name: 'Refugio Lopez',
    position: 'Engineer'
  },
  {
    image: '/imgs/team/david.png',
    name: 'David Parra',
    position: 'Engineer'
  },
]

// MANFUCATURING
export const manufacturingData = [
  {
    keyservices: 'ERP System Integration',
    icon: 'line-space icon dnaicon-erp'
  },
  {
    keyservices: 'Cybersecurity and Data Breach Protection',
    icon: 'line-space icon dnaicon-cybersecurity-protection'
  },
  {
    keyservices: 'Prevention in Network Outages and Interruptions',
    icon: 'line-space icon dnaicon-prevention'
  },
  {
    keyservices: 'Compliance Auditing and Management',
    icon: 'line-space icon dnaicon-audit'
  },
  {
    keyservices: 'Product Education and Training',
    icon: 'line-space icon dnaicon-training'
  },
  {
    keyservices: 'Business Continuity and Disaster Recovery',
    icon: 'line-space icon dnaicon-arrow-settings'
  },
  {
    keyservices: 'Reduce Workloads',
    icon: 'line-space icon dnaicon-reduce-workloads'
  },
  {
    keyservices: 'Streamline Documentation and Communication through Departments',
    icon: 'line-space icon dnaicon-documentation'
  },
  {
    keyservices: 'Asset and IT System Management',
    icon: 'line-space icon dnaicon-asset-it-management'
  },
]

// AIRPORT
export const airportData = [
  {
    keyservices: 'ERP System Integration',
    icon: 'line-space icon dnaicon-erp'
  },
  {
    keyservices: 'Artificial Intelligence and Predective Software',
    icon: 'line-space icon dnaicon-chip-data-security'
  },
  {
    keyservices: 'Onboard Connectivity',
    icon: 'line-space icon dnaicon-connectivity'
  },
  {
    keyservices: 'Product Education and Training',
    icon: 'line-space icon dnaicon-training'
  },
  {
    keyservices: 'Business Continuity and Disaster Recovery',
    icon: 'line-space icon dnaicon-arrow-settings'
  },
  {
    keyservices: 'Compliance Auditing and Management (SOC2)',
    icon: 'line-space icon dnaicon-soc2-logo'
  },
  {
    keyservices: 'Prevention in Network Outages and Interruptions',
    icon: 'line-space icon dnaicon-prevention'
  },
]

// CORPORATE
export const corporateData = [
  {
    keyservices: 'ERP System Integration',
    icon: 'line-space icon dnaicon-erp'
  },
  {
    keyservices: 'Product Education and Training',
    icon: 'line-space icon dnaicon-training'
  },
  {
    keyservices: 'Business Continuity and Disaster Recovery',
    icon: 'line-space icon dnaicon-arrow-settings'
  },
  {
    keyservices: 'Reduce Workloads',
    icon: 'line-space icon dnaicon-reduce-workloads'
  },
  {
    keyservices: 'Compliance Auditing and Management (SOC2)',
    icon: 'line-space icon dnaicon-audit'
  },
  {
    keyservices: 'Streamline Documentation and Communication through Departments',
    icon: 'line-space icon dnaicon-documentation'
  },
  {
    keyservices: 'Prevention in Network Outages and Interruptions',
    icon: 'line-space icon dnaicon-network'
  },
  {
    keyservices: 'Cybersecurity and Data Breach Protection',
    icon: 'line-space icon dnaicon-cybersecurity-protection'
  },
  {
    keyservices: 'Guaranteed Industry Compliance Standards',
    icon: 'line-space icon dnaicon-guaranteed'
  },

]

// FINANCE 
export const financeData = [
  {
    keyservices: 'ERP System Integration',
    icon: 'line-space icon dnaicon-erp'
  },
  {
    keyservices: 'Compliance Auditing and Management (FINRA, PCI, NIST)',
    icon: 'line-space icon dnaicon-audit'
  },
  {
    keyservices: 'Cybersecurity and Data Breach Protection',
    icon: 'line-space icon dnaicon-cybersecurity-protection'
  },
  {
    keyservices: 'Business Continuity and Disaster Recovery',
    icon: 'line-space icon dnaicon-arrow-circle'
  },
  {
    keyservices: 'Prevention in Network Outages and Interruptions',
    icon: 'line-space icon dnaicon-network'
  },
  {
    keyservices: 'Streamline Documentation and Communication through Departments',
    icon: 'line-space icon dnaicon-documentation'
  },
  {
    keyservices: 'Decrease Overhead Costs',
    icon: 'line-space icon dnaicon-decrease-overhead'
  },
  {
    keyservices: 'Product Education and Training',
    icon: 'line-space icon dnaicon-training'
  },
]
// LAW 
export const lawData = [
  {
    keyservices: 'ERP System Integration',
    icon: 'line-space icon dnaicon-erp'
  },
  {
    keyservices: 'Cybersecurity and Data Breach Protection',
    icon: 'line-space icon dnaicon-cybersecurity-protection'
  },
  {
    keyservices: 'Streamline Documentation and Communication through Departments',
    icon: 'line-space icon dnaicon-documentation'
  },
  {
    keyservices: 'Business Continuity and Disaster Recovery',
    icon: 'line-space icon dnaicon-arrow-settings'
  },
  {
    keyservices: 'Prevention in Network Outages and Interruptions',
    icon: 'line-space icon dnaicon-prevention'
  },
  {
    keyservices: 'Compliance Auditing and Management (HIPAA, SOC2)',
    icon: 'line-space icon dnaicon-audit'
  },
  {
    keyservices: 'Secure Remote Data Access',
    icon: 'line-space icon dnaicon-remote'
  },
  {
    keyservices: 'Product Education and Training',
    icon: 'line-space icon dnaicon-training'
  },
  {
    keyservices: 'Daily Dark Web Monitoring and Reporting',
    icon: 'line-space icon dnaicon-darkweb-monitoring'
  },
]

// GOVERNMENT 
export const governmentData = [
  {
    keyservices: 'ERP System Integration',
    icon: 'line-space icon dnaicon-erp'
  },
  {
    keyservices: 'Cybersecurity and Data Breach Protection',
    icon: 'line-space icon dnaicon-cybersecurity-protection'
  },
  {
    keyservices: 'Streamline Documentation and Communication through Departments',
    icon: 'line-space icon dnaicon-documentation'
  },
  {
    keyservices: 'Prevention in Network Outages and Interruptions',
    icon: 'line-space icon dnaicon-prevention'
  },
  {
    keyservices: 'Compliance Auditing and Management (SOC2)',
    icon: 'line-space icon dnaicon-audit'
  },
  {
    keyservices: 'Secure Remote Data Access',
    icon: 'line-space icon dnaicon-remote'
  },
  {
    keyservices: 'Product Education and Training',
    icon: 'line-space icon dnaicon-training'
  },
  {
    keyservices: 'Budget Planning',
    icon: 'line-space icon dnaicon-strategy'
  },
  {
    keyservices: 'No Capital Expenditures',
    icon: 'line-space icon dnaicon-capital'
  },
  {
    keyservices: 'Asset and IT System Management',
    icon: 'line-space icon dnaicon-asset-it-management'
  },
]
// CONSTRUCTION 
export const constructionData = [
  {
    keyservices: 'ERP System Integration',
    icon: 'line-space icon dnaicon-erp'
  },
  {
    keyservices: 'Streamline Documentation and Communication through Departments',
    icon: 'line-space icon dnaicon-documentation'
  },
  {
    keyservices: 'Secure Remote Data Access',
    icon: 'line-space icon dnaicon-remote'
  },
  {
    keyservices: 'Product Education and Training',
    icon: 'line-space icon dnaicon-training'
  },
  {
    keyservices: 'Business Continuity and Disaster Recovery',
    icon: 'line-space icon dnaicon-arrow-settings'
  },
  {
    keyservices: 'Prevention in Network Outages and Interruptions',
    icon: 'line-space icon dnaicon-prevention'
  },
  {
    keyservices: 'Mobile Device Management',
    icon: 'line-space icon dnaicon-mobile-appdev'
  },
  {
    keyservices: 'Cybersecurity and Data Breach Protection',
    icon: 'line-space icon dnaicon-cybersecurity-protection'
  },
  {
    keyservices: 'Compliance Auditing and Management (SOC2)',
    icon: 'line-space icon dnaicon-audit'
  },
  {
    keyservices: 'Asset and IT System Management',
    icon: 'line-space icon dnaicon-asset-it-management'
  },
]

// MEDICAL 
export const medicalData = [
  {
    keyservices: 'ERP System Integration',
    icon: 'line-space icon dnaicon-erp'
  },
  {
    keyservices: 'Optimization and Healthcare Services',
    icon: 'line-space icon dnaicon-optimization'
  },
  {
    keyservices: 'Reduce Workloads',
    icon: 'line-space icon dnaicon-reduce-workloads'
  },
  {
    keyservices: 'Extensive Cybersecurity',
    icon: 'line-space icon dnaicon-extensive'
  },
  {
    keyservices: 'Compliance Auditing and Management (HIPAA, SOC2)',
    icon: 'line-space icon dnaicon-audit'
  },
  {
    keyservices: 'Business Continuity and Disaster Recovery',
    icon: 'line-space icon dnaicon-arrow-settings'
  },
  {
    keyservices: 'Prevention in Network Outages and Interruptions',
    icon: 'line-space icon dnaicon-prevention'
  },
  {
    keyservices: 'Product Education and Training',
    icon: 'line-space icon dnaicon-training'
  },
  {
    keyservices: 'Cybersecurity and Data Breach Protection',
    icon: 'line-space icon dnaicon-cybersecurity-protection'
  },
]

// IT DEPARTMENT 
export const itdepartmentData = [
  {
    keyservices: 'ERP System Integration',
    icon: 'line-space icon dnaicon-erp'
  },
  {
    keyservices: 'Cloud Management',
    icon: 'line-space icon dnaicon-cloud-settings'
  },
  {
    keyservices: 'Network Installation and Management',
    icon: 'line-space icon dnaicon-network'
  },
  {
    keyservices: 'Business Continuity & Disaster Recovery',
    icon: 'line-space icon dnaicon-arrow-settings'
  },
  {
    keyservices: 'IT Consultation and Strategy',
    icon: 'line-space icon dnaicon-consultation'
  },
  {
    keyservices: 'VOIP',
    icon: 'line-space icon dnaicon-voip'
  },
  {
    keyservices: 'Prevention in Network Outages and Interruptions',
    icon: 'line-space icon dnaicon-prevention'
  },
  {
    keyservices: 'Mobile Device Management',
    icon: 'line-space icon dnaicon-mobile-appdev'
  },
  {
    keyservices: 'Secure Remote Data Access',
    icon: 'line-space icon dnaicon-remote'
  },
  {
    keyservices: 'Email Protection and Web Filtering',
    icon: 'line-space icon dnaicon-email-protection'
  },
  {
    keyservices: '24/7 365 Live Help Desk and Onsite/Remote Support Services',
    icon: 'line-space icon dnaicon-live-helpdesk'
  },
  {
    keyservices: '"Work From Home" Configuration',
    icon: 'line-space icon dnaicon-workfromhome'
  },
  {
    keyservices: 'IT Strategy, Budgeting, and Technology Planning',
    icon: 'line-space icon dnaicon-strategy'
  },
  {
    keyservices: 'Product Onboarding and Training',
    icon: 'line-space icon dnaicon-training'
  },
  {
    keyservices: 'IT Security Threat Prevention and Remediation',
    icon: 'line-space icon dnaicon-cybersecurity-protection'
  },
  {
    keyservices: 'Patch Management',
    icon: 'line-space icon dnaicon-patch-management'
  },
  {
    keyservices: 'Technology Rollouts and Upgrades',
    icon: 'line-space icon dnaicon-technology-upgrade'
  },
  {
    keyservices: 'Best Practice Implementation',
    icon: 'line-space icon dnaicon-cyber-insurance'
  },
]

// SECURITY COMPLIANCE
export const securityData = [
  {
    keyservices: 'ERP System Integration',
    icon: 'line-space icon dnaicon-erp'
  },
  {
    keyservices: 'Employee Security Training',
    icon: 'line-space icon dnaicon-training'
  },
  {
    keyservices: 'Phishing Email Simulations',
    icon: 'line-space icon dnaicon-email-protection'
  },
  {
    keyservices: 'Daily Dark Web Monitoring and Reporting',
    icon: 'line-space icon dnaicon-darkweb-monitoring'
  },
  {
    keyservices: 'California Consumer Privacy Act (CCPA)',
    icon: 'line-space icon dnaicon-ccpa'
  },
  {
    keyservices: 'Compliance Auditing and Management',
    icon: 'line-space icon dnaicon-audit'
  },
  {
    keyservices: 'Cyber Insurance',
    icon: 'line-space icon dnaicon-cyber-insurance'
  },
  {
    keyservices: 'Cybersecurity',
    icon: 'line-space icon dnaicon-chip-data-security'
  },
  {
    keyservices: 'HIPAA',
    icon: 'line-space icon dnaicon-hippa-logo'
  },
  {
    keyservices: 'NIST',
    icon: 'line-space icon dnaicon-nist-logo'
  },
  {
    keyservices: 'SOC2',
    icon: 'line-space icon dnaicon-soc2-logo'
  },
  {
    keyservices: 'PCI',
    icon: 'line-space icon dnaicon-pci'
  },
  {
    keyservices: 'ITAR', //added itar
    icon: 'line-space icon dnaicon-itar'
  },
  {
    keyservices: 'Network Security',
    icon: 'line-space icon dnaicon-network-security'
  },
  {
    keyservices: 'Data Breach Protection',
    icon: 'line-space icon dnaicon-data-protection'
  }
  
]


// SOFTWARE DEVELOPMENT
export const softdevData = [
  {
    keyservices: 'ERP System Integration',
    icon: 'line-space icon dnaicon-erp'
  },
  {
    keyservices: 'Custom Business Software',
    icon: 'line-space icon dnaicon-custom-business'
  },
  {
    keyservices: 'Mobile App Development',
    icon: 'line-space icon dnaicon-mobile-appdev'
  },
  {
    keyservices: 'Web Development',
    icon: 'line-space icon dnaicon-dev'
  },
  {
    keyservices: 'Artificial Intelligence and Predictive Software',
    icon: 'line-space icon dnaicon-chip-data-security'
  },
  {
    keyservices: 'Software System Analysis',
    icon: 'line-space icon dnaicon-system-analysis'
  },
  {
    keyservices: 'Database Management',
    icon: 'line-space icon dnaicon-database-management'
  }
]

// Terms and conditions price table
export const termsTableData = [
  {
    tdl: '.ae',
    price: '1-5 years @ $48.95 per year'
  },
  {
    tdl: '.asia',
    price: '1-10 years @ $20.95 per year'
  },
  {
    tdl: '.at',
    price: '1 years @ $15.50 per year'
  },
  {
    tdl: '.be',
    price: '1 years @ $12.95 per year'
  },
  {
    tdl: '.biz',
    price: '1-10 years @ $13.95 per year'
  },
  {
    tdl: '.ca',
    price: '1-10 years @ $19.95 per year'
  },
  {
    tdl: '.cc',
    price: '1-10 years @ $29.95 per year'
  },
  {
    tdl: '.ch',
    price: '1 years @ $13.50 per year'
  },
  {
    tdl: '.club',
    price: '1-10 years @ $16.00 per year'
  },
  {
    tdl: '.cn',
    price: '1-10 years @ $10.50 per year'
  },
  {
    tdl: '.co',
    price: '1-5 years @ $35.95 per year'
  },
  {
    tdl: '.co .il',
    price: '1 years @ $23.00 per year'
  },
  {
    tdl: '.com',
    price: '1-10 years @ $12.95 per year'
  },
  {
    tdl: '.com .au',
    price: '2 years @ $32.95 per year'
  },
  {
    tdl: '.com .cn',
    price: '1-10 years @ $10.50 per year'
  },
  {
    tdl: '.com .mx',
    price: '1-5 years @ $65.95 per year'
  },
  {
    tdl: '.company',
    price: '1-10 years @ $19.00 per year'
  },
  {
    tdl: '.com .tw',
    price: '1-10 years @ $33.95 per year'
  },
  {
    tdl: '.co .nz',
    price: '1-10 years @ $25.45 per year'
  },
  {
    tdl: '.co .uk',
    price: '1-10 years @ $10.95 per year'
  },
  {
    tdl: '.co .za',
    price: '1 years @ $18.95 per year'
  },
  {
    tdl: '.de',
    price: '1 years @ $12.95 per year'
  },
  {
    tdl: '.deals',
    price: '1-10 years @ $28.00 per year'
  },
  {
    tdl: '.dk',
    price: '1-3,5 years @ $27.00 per year'
  },
  {
    tdl: '.es',
    price: '1-5 years @ $13.95 per year'
  },
  {
    tdl: '.eu',
    price: '1-10 years @ $10.95 per year'
  },
  {
    tdl: '.family',
    price: '1-10 years @ $21.00 per year'
  },
  {
    tdl: '.fi',
    price: '1-5 years @ $16.00 per year'
  },
  {
    tdl: '.fr',
    price: '1 years @ $11.00 per year'
  },
  {
    tdl: '.gr',
    price: '2 years @ $28.00 per year'
  },
  {
    tdl: '.guru',
    price: '1-10 years @ $28.00 per year'
  },
  {
    tdl: '.host',
    price: '1-10 years @ $89.95 per year'
  },
  {
    tdl: '.hosting',
    price: '1-10 years @ $345.00 per year'
  },
  {
    tdl: '.idv .tw',
    price: '1-10 years @ $33.95 per year'
  },
  {
    tdl: '.ie',
    price: '1-10 years @ $21.50 per year'
  },
  {
    tdl: '.in',
    price: '1-10 years @ $15.95 per year'
  },
  {
    tdl: '.info',
    price: '1-10 years @ $14.95 per year'
  },
  {
    tdl: '.it',
    price: '1 years @ $10.50 per year'
  },
  {
    tdl: '.jp',
    price: '1 years @ $41.00 per year'
  },
  {
    tdl: '.me',
    price: '1-10 years @ $17.50 per year'
  },
  {
    tdl: '.me .uk',
    price: '1-10 years @ $10.95 per year'
  },
  {
    tdl: '.mobi',
    price: '1-10 years @ $24.95 per year'
  },
  {
    tdl: '.mx',
    price: '1-5 years @ $65.95 per year'
  },
  {
    tdl: '.name',
    price: '1-10 years @ $14.95 per year'
  },
  {
    tdl: '.net',
    price: '1-10 years @ $12.95 per year'
  },
  {
    tdl: '.net .au',
    price: '2 years @ $34.95 per year'
  },
  {
    tdl: '.net .cn',
    price: '1-10 years @ $10.50 per year'
  },
  {
    tdl: '.net .nz',
    price: '1-10 years @ $25.45 per year'
  },
  {
    tdl: '.news',
    price: '1-10 years @ $21.00 per year'
  },
  {
    tdl: '.ninja',
    price: '1-10 years @ $18.00 per year'
  },
  {
    tdl: '.nl',
    price: '1 years @ $10.95 per year'
  },
  {
    tdl: '.no',
    price: '1 years @ $13.50 per year'
  },
  {
    tdl: '.nu',
    price: '1-10 years @ $18.00 per year'
  },
  {
    tdl: '.org',
    price: '1-10 years @ $12.95 per year'
  },
  {
    tdl: '.org .au',
    price: '2 years @ $44.95 per year'
  },
  {
    tdl: '.org .cn',
    price: '1-10 years @ $10.50 per year'
  },
  {
    tdl: '.org .nz',
    price: '1-10 years @ $25.45 per year'
  },
  {
    tdl: '.org .tw',
    price: '1-10 years @ $33.95 per year'
  },
  {
    tdl: '.org .uk',
    price: '1-10 years @ $10.95 per year'
  },
  {
    tdl: '.party',
    price: '1-10 years @ $29.00 per year'
  },
  {
    tdl: '.ph',
    price: '1-2,5,10 years @ $52.45 per year'
  },
  {
    tdl: '.pl',
    price: '1-3 years @ $35.95 per year'
  },
  {
    tdl: '.pro',
    price: '1-10 years @ $17.00 per year'
  },
  {
    tdl: '.pt',
    price: '1,3,5 years @ $18.00 per year'
  },
  {
    tdl: '.qa',
    price: '1-5 years @ $46.95 per year'
  },
  {
    tdl: '.ru',
    price: '1 years @ $9.95 per year'
  },
  {
    tdl: '.se',
    price: '1-10 years @ $16.50 per year'
  },
  {
    tdl: '.services',
    price: '1-10 years @ $28.00 per year'
  },
  {
    tdl: '.site',
    price: '1-10 years @ $25.00 per year'
  },
  {
    tdl: '.solutions',
    price: '1-10 years @ $19.00 per year'
  },
  {
    tdl: '.space',
    price: '1-10 years @ $9.50 per year'
  },
  {
    tdl: '.top',
    price: '1-10 years @ $14.00 per year'
  },
  {
    tdl: '.tv',
    price: '1-10 years @ $38.95 per year'
  },
  {
    tdl: '.tw',
    price: '1-10 years @ $33.95 per year'
  },
  {
    tdl: '.uk',
    price: '1-10 years @ $10.95 per year'
  },
  {
    tdl: '.us',
    price: '1-10 years @ $12.95 per year'
  },
  {
    tdl: '.we .bs',
    price: '1-10 years @ $4.95 per year'
  },
  {
    tdl: '.website',
    price: '1-10 years @ $27.95 per year'
  },
  {
    tdl: '.ws',
    price: '1-10 years @ $25.95 per year'
  },
  {
    tdl: '.xyz',
    price: '1-10 years @ $12.00 per year'
  }
]


export const pageContact = {
  audit: 'audit',
  consultant: 'consultant',
  roadmap: 'roadmap',
  darkwebscan: 'darkwebscan',
  pdf_file: '/downloadables/IT_Services_Audit.pdf',
  darkweb: '/downloadables/Dark_Web_Report.pdf'
}