import React from 'react';
import { termsTableData } from '../common/data'

function TermsTablePrice(props) {
    return (
       
        <div>
            <table className="terms_table">
                <tr>
                    <th>TLD</th>
                    <th>min/max period of registration @ min period price</th>
                </tr>
                {
                    termsTableData.map(d =>
                        <tr>
                            <td>{d.tdl}</td>
                            <td>{d.price}</td>
                        </tr>
                    ) 
                }
            </table>
        </div>
    )
}

export default TermsTablePrice

