import React, { Component } from 'react'
import {NavLink} from 'react-router-dom'
import { pages } from '../common/data'

export default class TermsofService extends Component {
    constructor(props) {
        super(props)
        this.state = {
          activeTab: ''
        }
      }

    render() {
        const { activeTab } = this.state;
        console.log({activeTab})

        return (
            <div id="terms-page">
                <div className="dark-blue">
                    <h1 className="terms__title">CORPORATE TECHNOLOGY TERMS OF SERVICE</h1>
                </div>
                <p className="terms__description">When you create a new Corporate Technology account, you have to agree with our Terms of use. Read them carefully to learn more about everything we, as a vendor, guarantee you and your obligations as a customer.
                </p>
                <div className="main terms__container" id="section1">
                    <div className="terms__container--left">
                        <div style={{"margin": "1.5rem 0"}}>
                            <h1 className="service__title">Web Hosting Terms</h1>
                            <ul className="terms__ul">
                                <li><i className="icon dnaicon-dash"></i><NavLink onClick={() => this.setState({ activeTab: pages.parent })}
                                to="/hosting-terms">Hosting Terms</NavLink></li>
                                <li><i className="icon dnaicon-dash"></i><NavLink onClick={() => this.setState({ activeTab: pages.parent })}
                                to="/acceptable-use-policy">Acceptable Use Policy</NavLink></li>
                                <li><i className="icon dnaicon-dash"></i><NavLink to="/service-level-guarantee">Service Level Guarantees</NavLink></li>
                            </ul>
                        </div>
                        <div style={{"margin": "1.5rem 0"}}>
                            <h1 className="service__title">Semi-dedicated Servers Terms</h1>
                            <ul className="terms__ul">
                                <li><i className="icon dnaicon-dash"></i><NavLink to="/semi-dedicated-server-terms">Semi-Dedicated Servers Terms</NavLink></li>
                                <li><i className="icon dnaicon-dash"></i><NavLink to="/semi-dedicated-acceptable-use-policy">Semi-Dedicated Acceptable Use Policy</NavLink></li>
                                <li><i className="icon dnaicon-dash"></i><NavLink to="/semi-dedicated-service-guarantee">Semi-Dedicated Service Guarantees</NavLink></li>
                            </ul>
                        </div>
                        <div style={{"margin": "1.5rem 0"}}>
                            <h1 className="service__title">General Terms</h1>
                            <ul className="terms__ul">
                                <li><i className="icon dnaicon-dash"></i><NavLink to="/general-terms-order-verification">Order Verification Policy</NavLink></li>
                                <li><i className="icon dnaicon-dash"></i><NavLink to="/general-terms-privacy-policy">Privacy Policy</NavLink></li>
                                <li><i className="icon dnaicon-dash"></i><NavLink to="/general-terms-domain-registration">Domain Name Registration Agreement</NavLink></li>
                                <li><i className="icon dnaicon-dash"></i><NavLink to="/general-terms-domain-resolution">Domain Name Resolution Policy</NavLink></li>
                                <li><i className="icon dnaicon-dash"></i><NavLink to="/general-terms-id-protection">ID Protection Service Agreement</NavLink></li>
                                <li><i className="icon dnaicon-dash"></i><NavLink to="/general-terms-cancellation-refund">Cancelation Refund Policy</NavLink></li>
                            </ul>
                        </div>
                    </div>
                    <div className="terms__container--right">
                        <div style={{"margin": "1.5rem 0"}}>
                            <h1 className="service__title">Virtual Private Service Terms</h1>
                            <ul className="terms__ul">
                                <li><i className="icon dnaicon-dash"></i><NavLink to="/virtual-private-terms">VPS Terms</NavLink></li>
                                <li><i className="icon dnaicon-dash"></i><NavLink to="/virtual-private-acceptable-use">VPS Acceptable Use Policy</NavLink></li>
                                <li><i className="icon dnaicon-dash"></i><NavLink to="/virtual-private-service-level">VPS Service Level Guarantees</NavLink></li>
                            </ul>
                        </div>
                        <div style={{"margin": "1.5rem 0"}}>
                            <h1 className="service__title">Dedicated Servers Terms</h1>
                            <ul className="terms__ul">
                                <li><i className="icon dnaicon-dash"></i><NavLink to="/dedicated-server-colocation-telecommunication">Colocation and Telecommunication Terms</NavLink></li>
                                <li><i className="icon dnaicon-dash"></i><NavLink to="/dedicated-server">Dedicated Servers Terms</NavLink></li>
                                <li><i className="icon dnaicon-dash"></i><NavLink to="/dedicated-server-acceptable-use-policy">Dedicated Servers Acceptable Use Policy</NavLink></li>
                                <li><i className="icon dnaicon-dash"></i><NavLink to="/dedicated-server-service-guarantee">Dedicated Servers Service Guarantees</NavLink></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
